.brand {
    /* background-color: var(--primary); */
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.brandicon {
    margin-left: 0px;
    size: 40px;
    color: white;
    font-size: 30px;
    margin-right: 5px;
}

.settingicon {
    margin-left: 0px;
    size: 30px;
    color: white;
    font-size: 20px;
    margin-right: 15px;
}

.meNavBar {
    margin-left: auto;
    size: 30px;
    color: white;
    font-size: 20px;
    float: right;
    margin-right: 15px;
}

.brandName {
    font-size: 18px;
    color: white;
    margin-left: 10px;
    font-weight: 600;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

/* global color codes */
:root {
    /* --primary: #f4681d; */
    --blue: #4679bd;
    --grey: #ddd;
    --W200: 200px;
    --Lft: left;
}


.projectBar {
    background-color: #fcfcfc;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.projectBaricon {
    margin-left: 10px;
    size: 30px;
    color: black;
    font-size: 22px;
    margin-right: 15px;
    color: #f7d166 !important;
}

.brandName {
    font-size: 18px;
    color: white;
    font-weight: 600;
}

.waypoint i {
    color: #f7d166 !important;
}

.navBar {
    background-color: white;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 0.1px solid #EAECEE;
}

.commandBar {
    background-color: white;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    float: right;
    width: 100%;
    border-bottom: 2px solid #EAECEE;
}

.navicon {
    margin-left: 10px;
    size: 40px;
    color: black;
    font-size: 25px;
    margin-right: 15px;
}

.brandName {
    font-size: 18px;
    color: white;
    font-weight: 600;
}

.menuCardIcon {
    margin-left: 25px;
    size: 60px;
    color: black;
    font-size: 25px;
    margin-right: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
}

/*.ms-DetailsList{*/
/*height: calc(100vh);*/
/*}*/

.header-bar {
    background-color: #ee7423;
    border-bottom: 1px solid #854114;
    color: white;
    align-items: center;
}

.header-bar a {
    color: white;
    display: flex;
}

.header-bar a:hover {
    text-decoration: none;
}

.header-bar i {
    font-size: 2rem;
    font-weight: bold;
}

.header-bar i:hover {
    text-decoration: none;
}

.header-bar label {
    color: white;
    font-size: 1.1rem;
    font-weight: normal;
    margin-left: 0.2rem !important;
}

.recent-project-bar {
    background-color: #e6e6e6;
    border-bottom: 1px solid #d2d2d2;
    white-space: nowrap;
}

.recent-project-bar a {
    font-size: 0.8rem;
    color: #323130;
    display: flex;
}

.recent-project-bar i {
    font-size: 1rem;
    line-height: 1.2rem;
    padding-right: 0.2rem;
    opacity: 0.6;
}

.recent-project-bar i.root-199 {
    opacity: 0.9;
    padding-left: 2px;
}

.recent-project-bar .gap-control {
    margin-left: 20px !important;
}

.content-holder {
    width: calc(100% - 30px);
    border: 1px solid #bab8b7;
    margin-top: 15px !important;
}

.content-holder-inner {
    margin-top: 15px !important;
    overflow: hidden;
}

.holder-inner {
    border: 1px solid #d2d2d2;
}

.title-bar-1 {
    border-bottom: 1px solid #bab8b7;
    width: 100%;
    padding: 0.5rem;
}

.title-bar-1 a {
    line-height: 0;
    display: flex;
    align-items: center;
}

.title-bar-1 a:hover,
.title-bar-1 a:hover i {
    text-decoration: none;
}

.title-bar-1 i {
    font-size: 1.4rem;
    line-height: 1.2rem;
    color: #323130;
    padding-right: 0.5rem;
}

.title-bar-1 label {
    padding: 0;
    cursor: pointer;
}

/* match with the current app */
.title-bar-1.title-bar-1.title-bar-inner {
    background-color: #fafafa;
    border-bottom: 1px solid #d2d2d2;
}

.title-bar-1.title-bar-inner,
.title-bar-1.title-bar-inner label {
    cursor: default;
}

.title-bar-1.title-bar-inner label {
    margin-left: 0;
}

.inner-container {
    padding: 0.5rem;
}

.project-detail-list.inner-container {
    padding: 0 0 1rem 1rem;
    width: 80%;
    height: 80vh;
}

.project-list-header {
    padding: 0.5rem;
    min-height: 46px;
    border-bottom: 1px solid #d2d2d2;
}

.project-list-header.right-panel {
    padding: 0 0.5rem 0 0;
}

.project-list-header.right-panel.ppb {
    min-height: 44px;
}

.project-list-header.new-design {
    padding: 0 0.5rem 0 0;
    min-height: 28px;
}

/* .project-list-header button,
.ms-CommandBar,
.ms-CommandBar .ms-Button.ms-Button--commandBar {
    background-color: #ffffff !important;
}
.project-list-header button.ms-Button--default {
    background-color: #ffffff !important;
} */
.ms-CommandBar {
    padding-right: 0;
    padding-left: 0;
}

.project-list-grid {
    margin: 0;
}

.filter-panel-left {
    height: 47em;
    background-color: #fafafa;
    /* background-color: #e6e6e6; */
    border-right: 1px solid #d2d2d2;
    transition: all 0.5s ease;
    width: 20%;
    max-width: 189px !important;
}

.filter-panel-left.panel-move {
    width: 40px;
    white-space: nowrap;
}

.filter-panel-left .ms-Label {
    white-space: nowrap;
}

.filter-panel-left .ms-TooltipHost {
    display: none;
}

.ms-Grid-col.padding-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.ms-Grid-col.padding-left-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.grid-righter {
    flex-grow: 1;
    border-right: 1px solid #d2d2d2;
}

/* ------------------------------- */
/* .grid-righter.column-filter-activated .wrapper-46 {
    height: calc(100vh - 269px);
} */
.ms-Grid .grid-righter .ms-DetailsList-headerWrapper,
.grid-righter .ms-DetailsList-contentWrapper {
    position: static;
    transition: all linear 0.2s;
}

.grid-righter.column-filter-activated .ms-DetailsList-headerWrapper,
.grid-righter.column-filter-activated .footer-as-filter {
    position: absolute;
    top: 0;
}

.grid-righter.column-filter-activated .footer-as-filter {
    top: 47px;
}

.grid-righter.column-filter-activated .ms-DetailsList-contentWrapper {
    padding-top: 103px;
}

.ms-Grid .ms-DetailsRow-cell {
    color: #494847;
    font-size: 14px;
}

.ms-Grid .ms-DetailsRow.is-selected .ms-DetailsRow-cell {
    font-weight: bold;
}

/* ------------------------------- */

.chkbox-position {
    margin-top: 33px;
}

.back-arrow,
.fwd-arrow {
    cursor: pointer;
    font-size: 18px;
}

.fwd-arrow {
    display: none;
}

.filter-data {
    margin-top: 0.3rem;
}

.filter-data label {
    padding-bottom: 0.8rem;
}

.filter-data .ms-SearchBox {
    width: 99%;
}

.filter-option-list {
    margin-top: 1.5rem;
    white-space: nowrap;
}

.filter-option-list label {
    padding-bottom: 0.8rem;
}

.filter-option-list button,
.filter-option-list a {
    display: flex;
    align-items: center;
}

.filter-option-list button i,
.filter-option-list a i {
    padding-right: 0.5rem;
}

.filter-option-list button:hover,
.filter-option-list a:hover {
    text-decoration: none;
}

.filter-option-list button i.root-143,
.filter-option-list a i.root-143 {
    font-size: 1.1rem;
    margin-left: -2px;
}

.filter-option-list a span {
    font-size: 14px;
    font-weight: 600;
}

.filter-option-list a i {
    font-size: 15px;
}

.project-detail-list .proj-detail-block {
    width: 100%;
    margin-top: 15px;
}

.project-detail-list .proj-detail-block a {
    width: 100%;
    border-bottom: 1px solid #bab8b7;
    display: block;
}

.project-detail-list .proj-detail-block a,
.project-detail-list .proj-detail-block a label {
    font-size: 1.1rem;
    cursor: default;
}

.project-detail-list .proj-detail-block a:hover,
.project-detail-list .proj-detail-block a:hover label {
    text-decoration: none;
}

.project-detail-list .proj-detail-block .proj-detail-content {
    padding-top: 0.5rem;
}

.project-detail-list .proj-detail-block .proj-detail-content.inner {
    /* width: 80%; */
    width: calc(100% - 20px);
}

.project-detail-list
.proj-detail-block
.proj-detail-content.group-on
.proj-detail-block
a,
.project-detail-list
.proj-detail-block
.proj-detail-content.group-on
.proj-detail-block
a
label {
    opacity: 0.8;
    font-size: 1rem;
}

.ms-Stack.css-168 {
    scroll-behavior: smooth;
}

.bookmark-panel.parent,
.bookmark-panel-responsive {
    width: 210px;
    height: 20rem;
    position: absolute;
    top: 100px;
    right: -1px;
    background-color: #ffffff;
    border: 1px solid #bab8b7;
    /*border-top: 1px solid #bab8b7;*/
    /*border-left: 1px solid #bab8b7;*/
    /*border-bottom: none;*/
    transition: all 0.2s linear;
}

.bookmark-panel.parent {
    width: calc(100% - 80%);
    /*// height: calc(100% - 3.75rem);*/
    height: calc(100vh - 174px);
    top: 45px;
    z-index: 1;
    border: 1px solid #d2d2d2;
    /*border-top: 1px solid #bab8b7;*/
    /*border-left: 1px solid #bab8b7;*/
    /*border-bottom: none;*/
}

.bookmark-panel-responsive {
    top: 46px;
    right: 17px;
    border-top: 0;
}

.bookmark-panel.parent a {
    font-size: 14px !important;
    display: block;
    padding: 1px 0;
}

.bookmark-panel.parent label {
    padding: 1px 0;
}

.bookmark-panel.parent .title {
    font-size: 16px;
    font-weight: bold;
    padding: 0.5rem;
    margin-top: 0;
    display: block;
    background-color: #ffffff;
}

.bookmark-panel.parent .inner-container {
    overflow: auto;
    margin-top: 0;
    padding-top: 0;
}

.bookmark-panel.parent i.ms-Icon--ChromeClose {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    display: none;
}

.bookmark-panel-responsive {
    width: auto;
    height: auto;
    cursor: pointer;
    padding: 8px 8px 5px;
    display: none;
}

.bookmark-panel-responsive i {
    font-size: 22px;
    /* color: #ee7423; */
}

.bookmark-panel-responsive i:hover {
    color: #854114;
}

.bookmark-panel.parent.panel-on i.ms-Icon--ChromeClose {
    display: none;
    font-size: 14px;
    opacity: 0.5;
}

.bookmark-panel .inner-container a {
    border-left: 4px solid #ffffff;
    padding-left: 10px;
}

.bookmark-panel .inner-container label.selected,
.bookmark-panel .inner-container a.selected {
    font-weight: bold;
    /* border-left: 4px solid #ee7423; */
}

.bookmark-panel .inner-container label.selected a {
    font-weight: bold;
    /* border-left: 4px solid #ee7423; */
    color: #854114;
}

.bookmark-panel .inner-container label.selected {
    border-left: 0;
}

.ms-Panel .ms-Panel-content {
    padding: 0;
}

.ms-Panel .project-list-header {
    padding: 0 0.5rem;
    min-height: auto;
}

.ms-Panel .ms-Panel-contentInner .ms-Panel-header {
    position: absolute;
    top: 0;
    width: 80%;
    margin-top: 8px;
}

.custom-detail-panel .ms-Panel-main {
    width: 60%;
}

.ms-Panel .ms-Panel-scrollableContent {
    overflow: hidden !important;
    position: relative;
    height: 100%;
}

/* const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
} */

.filter-panel-left.panel-move .ms-Label {
    visibility: hidden;
    white-space: nowrap;
}

.filter-panel-left.panel-move .ms-SearchBox.txt-filter-1 {
    border-width: 0;
    background-color: transparent;
}

.filter-panel-left.panel-move .ms-SearchBox-field {
    visibility: hidden;
}

.filter-panel-left.panel-move .ms-SearchBox-iconContainer {
    text-align: left;
}

.filter-panel-left.panel-move .back-arrow {
    display: none;
}

.filter-panel-left.panel-move .fwd-arrow {
    display: block;
}

.filter-panel-left.panel-move .filter-option-list button span,
.filter-panel-left.panel-move .filter-option-list a span {
    visibility: hidden;
}

.filter-panel-left.panel-move .filter-option-list button i,
.filter-panel-left.panel-move .filter-option-list a i,
.filter-panel-left.panel-move .ms-SearchBox-icon,
.filter-panel-left .search-icon-only {
    font-size: 20px;
    padding-right: 0;
    padding-left: 2px;
    transition: all 0.2s linear;
    font-weight: normal;
}

.filter-panel-left.panel-move .ms-SearchBox-icon {
    padding-left: 0;
    margin-left: -2px;
}

.filter-panel-left.panel-move .filter-option-list button i.root-143,
.filter-panel-left.panel-move .filter-option-list a i.root-143 {
    font-size: 22px;
}

.filter-panel-left.panel-move .filter-option-list .ms-Stack button {
    margin-bottom: 15px;
}

.filter-panel-left.panel-move
.ms-SearchBox.txt-filter-1
.ms-SearchBox-iconContainer {
    cursor: pointer;
}

.filter-panel-left.panel-move .ms-TooltipHost {
    display: inline;
}

.filter-panel-left.panel-move .filter-icon,
.filter-panel-left .search-icon-only,
.filter-panel-left.panel-move .ms-SearchBox.txt-filter-1 {
    display: none;
}

.filter-panel-left.panel-move .search-icon-only {
    display: block;
}

.filter-panel-left .search-icon-only {
    /* color: #ee7423; */
    margin-top: 0.5rem;
    cursor: pointer;
}

.filter-panel-left.panel-move .search-icon-only {
    font-size: 22px;
}

.padding-set-top {
    padding-top: 1rem;
}

/* Fabric ICON */
.filter-panel-left i,
.project-detail-list i {
    font-family: FabricMDL2Icons;
}

.filter-panel-left i.ms-SearchBox-icon {
    text-indent: -200000px;
    position: relative;
    font-size: 18px;
    margin-top: -2px;
}

.filter-panel-left i.ms-SearchBox-icon:before {
    content: '\E721';
    position: absolute;
    left: 5px;
    top: 2px;
    text-indent: 0;
}

i.ms-Icon--POISolid:before {
    content: '\F2D1';
}

i.ms-Icon--Waffle:before {
    content: '\ED89';
}

i.ms-Icon--FolderHorizontal:before {
    content: '\F12B';
}

i.ms-Icon--SelectAll:before {
    content: '\E8A9';
}

i.ms-Icon--GroupedList:before {
    content: '\EF74';
}

i.ms-Icon--Search:before {
    content: '\E721';
}

i.ms-Icon--GotoToday:before {
    content: '\E8D1';
}

i.ms-Icon--CalendarWeek:before {
    content: '\E8C0';
}

i.ms-Icon--CalendarReply:before {
    content: '\E8F5';
}

i.ms-Icon--FavoriteList:before {
    content: '\E728';
}

i.ms-Icon--Completed:before {
    content: '\E930';
}

i.ms-Icon--Back:before {
    content: '\E700';
}

i.ms-Icon--Forward:before {
    content: '\E700';
}

i.ms-Icon.ms-Icon--SearchBookmark:before {
    content: '\F5B8';
}

i.ms-Icon.ms-Icon--ChromeClose:before {
    content: '\E8BB';
}

/* ------------ */
.common-commandbar i,
.overflow-control i,
.ms-ContextualMenu i {
    font-family: FabricMDL2Icons;
    font-style: normal;
}

.common-commandbar [data-automation-id='addNewButton'] i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addNewButton'] i:before {
    content: '\E710';
}

.common-commandbar
[data-automation-id='addUploadButton']
i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addUploadButton'] i:before {
    content: '\E898';
}

.common-commandbar
[data-automation-id='addShareButton']
i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addShareButton'] i:before {
    content: '\E72D';
}

.common-commandbar
[data-automation-id='addDownloadButton']
i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addDownloadButton'] i:before {
    content: '\E896';
}

.common-commandbar
[data-automation-id='addMoveButton']
i.ms-Button-icon:before {
    content: '\E8DE';
}

.common-commandbar
[data-automation-id='addCopyButton']
i.ms-Button-icon:before {
    content: '\E8C8';
}

.common-commandbar [data-automation-id='renameButton'] i.ms-Button-icon:before {
    content: '\E70F';
}

.common-commandbar
[data-automation-id='addSortButton']
i.ms-Button-icon:before {
    content: '\E9D0';
}

.common-commandbar
[data-automation-id='addTilesButton']
i.ms-Button-icon:before {
    content: '\ECA5';
}

.common-commandbar
[data-automation-id='addInfoButton']
i.ms-Button-icon:before {
    content: '\E946';
}

/* ------------ */
.ms-ContextualMenu [data-automation-id='newEmailButton'] i:before {
    content: '\E715';
}

.ms-ContextualMenu
[data-automation-id='addCalendarButton']
i.ms-Icon-placeHolder:before {
    content: '\E787';
}

.ms-ContextualMenu
[data-automation-id='addMoveButton']
i.ms-Icon-placeHolder:before {
    content: '\E8DE';
}

.ms-ContextualMenu
[data-automation-id='addCopyButton']
i.ms-Icon-placeHolder:before {
    content: '\E8C8';
}

.ms-ContextualMenu
[data-automation-id='renameButton']
i.ms-Icon-placeHolder:before {
    content: '\E70F';
}

/* ------------ */
.overflow-control
.ms-OverflowSet-item:first-child
i[role='presentation'].ms-Icon-placeHolder:before {
    content: '\E710';
}

.overflow-control
.ms-OverflowSet-item:nth-child(2)
i[role='presentation'].ms-Icon-placeHolder:before {
    content: '\E898';
}

.overflow-control
.ms-OverflowSet-item:nth-child(3)
i[role='presentation'].ms-Icon-placeHolder:before {
    content: '\E72D';
}

/* ------------ */
.common-commandbar {
    width: 100%;
}

/* .common-commandbar .ms-Button:hover,
.overflow-control .ms-Button:hover {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(32, 31, 30);
} */
.overflow-control .ms-Button {
    padding: 14px 10px;
}

.overflow-control,
.accordian-filter-panel .ms-Button {
    position: absolute;
    top: 0;
    right: 2px;
}

.ms-Pivot.ms-Pivot--large.ms-Pivot--tabs .ms-Button {
    display: none !important;
}

.accordian-filter-panel .ms-Button {
    right: auto;
    margin-top: 6px;
}

.accordian-container {
    position: static;
    padding-top: 50px;
}

.common-commandbar .ms-Button-menuIcon,
.ms-OverflowSet i[data-icon-name='ChevronDown'],
.accordian-filter-panel i[data-icon-name='ChevronDown'],
.footer-as-filter i[data-icon-name='ChevronDown'],
.ms-OverflowSet i[data-icon-name='More'],
i[data-icon-name='ChevronRight'].ms-ContextualMenu-submenuIcon,
.ms-Panel-main i[data-icon-name='Cancel'],
.ms-DetailsList-headerWrapper i[data-icon-name='Page'],
.ms-DetailsList-headerWrapper i[data-icon-name='SortUp'] {
    text-indent: -20000000px;
    position: relative;
    color: rgb(243, 242, 241);
}

.common-commandbar i[data-icon-name='More']:before,
.common-commandbar i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='ChevronDown']:before,
.accordian-filter-panel i[data-icon-name='ChevronDown']:before,
.footer-as-filter i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='More']:before,
i[data-icon-name='ChevronRight'].ms-ContextualMenu-submenuIcon:before,
.ms-Panel-main i[data-icon-name='Cancel']:before,
.ms-DetailsList-headerWrapper i[data-icon-name='Page']:before,
.ms-DetailsList-headerWrapper i[data-icon-name='SortUp']:before {
    content: '\E712';
    position: absolute;
    top: 0;
    left: -9px;
    text-indent: 0;
    color: rgb(163, 162, 160);
}

.common-commandbar i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='More']:before {
    content: '\E70D';
    left: -5px;
}

.accordian-filter-panel i[data-icon-name='ChevronDown']:before,
.footer-as-filter i[data-icon-name='ChevronDown']:before {
    content: '\E70D';
    left: -14px;
}

.common-commandbar [data-automation-id='addNewButton'] {
    padding-right: 10px;
}

i[data-icon-name='ChevronRight'].ms-ContextualMenu-submenuIcon:before {
    content: '\E76C';
}

.ms-OverflowSet i[data-icon-name='More']:before {
    content: '\E712';
    left: -6px;
}

.common-commandbar .ms-OverflowSet i[data-icon-name='More']:before {
    content: '\E712';
    left: -9px;
}

.ms-Panel-main i[data-icon-name='Cancel']:before {
    content: '\E8BB';
}

.ms-DetailsList-headerWrapper i[data-icon-name='Page']:before {
    content: '\E7C3';
    left: 0;
}

.ms-DetailsList-headerWrapper i[data-icon-name='SortUp']:before {
    content: '\EE68';
    left: 4px;
}

/* ------------ */
/* ------------ */
@media only screen and (max-width: 1440px) {
    .filter-panel-left .back-arrow,
    .filter-panel-left.panel-move .back-arrow,
    .filter-panel-left .fwd-arrow,
    .filter-panel-left.panel-move .fwd-arrow {
        visibility: hidden;
    }

    .filter-panel-left {
        width: 40px;
        white-space: nowrap;
    }

    .filter-panel-left .ms-Label {
        visibility: hidden;
        white-space: nowrap;
    }

    .filter-panel-left.filter-focus .ms-Label,
    .filter-panel-left.filter-focus .ms-SearchBox-field {
        visibility: visible;
    }

    .filter-panel-left.filter-focus,
    .filter-panel-left:hover {
        width: 30%;
        max-width: 189px !important;
    }

    .filter-panel-left:hover .ms-Label,
    .filter-panel-left:hover .ms-SearchBox-field,
    .filter-panel-left.filter-focus .ms-Label,
    .filter-panel-left.filter-focus .ms-SearchBox-field {
        visibility: visible;
    }

    .filter-panel-left .ms-SearchBox.txt-filter-1 {
        border-width: 0;
        background-color: transparent;
    }

    .filter-panel-left .ms-SearchBox-field {
        visibility: hidden;
    }

    .filter-panel-left .ms-SearchBox-iconContainer {
        text-align: left;
    }

    .filter-panel-left:hover .ms-SearchBox.txt-filter-1,
    .filter-panel-left.filter-focus .ms-SearchBox.txt-filter-1 {
        border-width: 1px;
        background-color: #ffffff;
    }

    .filter-panel-left .filter-option-list button span,
    .filter-panel-left .filter-option-list a span {
        visibility: hidden;
    }

    .filter-panel-left:hover .filter-option-list button span,
    .filter-panel-left.filter-focus .filter-option-list button span,
    .filter-panel-left:hover .filter-option-list a span,
    .filter-panel-left.filter-focus .filter-option-list a span {
        visibility: visible;
    }

    .filter-panel-left .filter-option-list button i,
    .filter-panel-left .filter-option-list a i,
    .filter-panel-left .ms-SearchBox-icon {
        font-size: 20px;
        padding-right: 0;
        padding-left: 2px;
        transition: all 0.2s linear;
        font-weight: normal;
    }

    .filter-panel-left .ms-SearchBox-icon {
        padding-left: 0;
        margin-left: -2px;
    }

    .filter-panel-left .filter-option-list button i.root-143,
    .filter-panel-left .filter-option-list a i.root-143 {
        font-size: 22px;
    }

    .filter-panel-left .filter-option-list .ms-Stack button {
        margin-bottom: 15px;
    }

    .filter-panel-left:hover .filter-option-list button i,
    .filter-panel-left.filter-focus .filter-option-list button i,
    .filter-panel-left:hover .filter-option-list a i,
    .filter-panel-left.filter-focus .filter-option-list a i {
        font-size: 14px;
        padding-right: 0.5rem;
        padding-left: auto;
    }

    .filter-panel-left:hover .filter-option-list button i.root-143,
    .filter-panel-left.filter-focus .filter-option-list button i.root-143,
    .filter-panel-left:hover .filter-option-list a i.root-143,
    .filter-panel-left.filter-focus .filter-option-list a i.root-143 {
        font-size: 16px;
    }

    .filter-panel-left:hover .filter-option-list .ms-Stack button,
    .filter-panel-left.filter-focus .filter-option-list .ms-Stack button {
        margin-bottom: auto;
    }

    .filter-panel-left:hover .ms-SearchBox-icon,
    .filter-panel-left.filter-focus .ms-SearchBox-icon {
        font-size: 16px;
        padding-left: auto;
        margin-left: 8px;
    }

    .filter-panel-left .ms-TooltipHost,
    .filter-panel-left:hover .ms-TooltipHost {
        display: none;
    }

    .filter-panel-left .filter-icon,
    .filter-panel-left:hover .filter-icon {
        display: block;
    }

    .filter-panel-left.panel-move .ms-SearchBox.txt-filter-1 {
        display: block;
    }

    .filter-panel-left i.ms-SearchBox-icon:before {
        left: 0;
    }

    .project-detail-list .proj-detail-block .proj-detail-content.inner {
        width: calc(100% - 20px);
    }

    .bookmark-panel.parent {
        display: none;
    }

    .bookmark-panel-responsive,
    .bookmark-panel.parent.panel-on i.ms-Icon--ChromeClose {
        display: block;
    }

    .bookmark-panel i,
    .bookmark-panel.parent.panel-on {
        display: inline-flex;
    }
}

@media only screen and (min-width: 1440px) and (max-width: 1800px) {
    .filter-panel-left {
        width: 25%;
    }

    .project-detail-list.inner-container {
        width: 75%;
    }

    .bookmark-panel.parent {
        width: calc(100% - 75%);
    }
}

@media only screen and (max-width: 1000px) {
    .hidden-M {
        display: none !important;
    }
}

@media only screen and (max-width: 1279px) {
    .hidden-L {
        display: none !important;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1440px) {
    .hidden-XL {
        display: none !important;
    }
}

@media only screen and (min-width: 1100px) and (max-width: 1440px) {
    .project-detail-list.inner-container {
        width: 100%;
    }

    .ms-Grid-col.ms-lg6.grid-content-left {
        width: 52%;
    }

    .ms-Grid-col.ms-lg6.grid-content-right {
        width: 48%;
    }
}

@media only screen and (max-width: 1100px) {
    .ms-Grid-col.grid-content-left {
        width: 100%;
    }

    .ms-Grid-col.grid-content-right {
        display: none;
    }

    .bookmark-panel.parent {
        top: -1px;
        height: 100%;
    }

    .bookmark-panel-responsive {
        top: 0;
        right: 17px;
    }

    .grid-righter {
        border-right: 0;
    }
}

@media only screen and (max-width: 1440px) {
    .bookmark-panel-responsive {
        width: auto;
    }

    .bookmark-panel.parent {
        width: 220px;
    }

    .filter-panel-left .ms-TooltipHost .search-icon-only {
        display: none;
    }

    .filter-panel-left i.ms-SearchBox-icon {
        font-size: 22px;
        margin-left: -3px;
    }
}

.window-width-indicator {
    position: fixed;
    top: 6px;
    right: 15px;
    color: white;
    font-size: 12px;
    font-weight: normal;
}

.ms-DetailsHeader {
    padding-top: 0 !important;
}

.ms-DetailsList {
    min-height: 100px;
}

.qrListPane {
    height: 100% !important;
}

.is-disabled span i {
    color: #999999 !important;
}

.is-disabled label {
    color: #000000 !important;
}

.ms-Dialog-main {
    overflow: hidden;
}

.reactSelect__placeholder {
    top: 49% !important;
}

.reactSelect__indicator-separator {
    display: none !important;
}

.project-detail-list.inner-container.cpc {
    padding: 0 0 1rem 1rem;
    min-height: calc(100vh - 218px) !important;
}

.project-detail-list.inner-container.cab {
    padding: 0 0 1rem 1rem;
    min-height: calc(100vh - 218px) !important;
}

.project-detail-list.inner-container.withoutTitlePane {
    padding: 0 0 1rem 1rem;
    min-height: calc(100vh - 185px) !important;
}

.title-pane {
    /* background-color: #f4681d; */
    color: white;
    border-left: 1px solid #ddd;
}

.ck-editor__editable {
    min-height: 180px !important;
}

/* .ms-Suggestions .ms-SearchMore-button {
  display: none !important;
} */

.breadcrumb-bar {
    border: 1px solid rgb(96, 94, 92);
    height: 30px;
    margin-top: 0;
    margin-bottom: 5px;
}

@media only screen and (max-width: 1100px) {
    .resource-family {
        width: 100%;
    }
}

@media only screen and (max-width: 1280px) {
    .resource-family {
        width: 90%;
    }
}

@media only screen and (min-width: 1281px) {
    .resource-family {
        width: 94%;
    }
}

.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.center {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left {
    display: flex;
    justify-content: left;
}

.title-text {
    font-size: large;
    margin-bottom: 20px;
}

.marginTop {
    margin-top: 10px;
}

.borderRight {
    border-right: 0.1px solid #eaecee;
}

.borderBottom {
    border-Bottom: 0.1px solid #eaecee;
    width: 100vh
}

.fullHeight {
    height: 100vh;
    display: flex;
}

.fullCol {
    height: 55em;
    flex: 1 1;
}

.timeclockicon {
    color: aquamarine;
}


/*.ms-DetailsList{*/
/*height: calc(100vh);*/
/*}*/

.header-bar {
  background-color: #ee7423;
  border-bottom: 1px solid #854114;
  color: white;
  align-items: center;
}
.header-bar a {
  color: white;
  display: flex;
}
.header-bar a:hover {
  text-decoration: none;
}
.header-bar i {
  font-size: 2rem;
  font-weight: bold;
}
.header-bar i:hover {
  text-decoration: none;
}
.header-bar label {
  color: white;
  font-size: 1.1rem;
  font-weight: normal;
  margin-left: 0.2rem !important;
}
.recent-project-bar {
  background-color: #e6e6e6;
  border-bottom: 1px solid #d2d2d2;
  white-space: nowrap;
}
.recent-project-bar a {
  font-size: 0.8rem;
  color: #323130;
  display: flex;
}
.recent-project-bar i {
  font-size: 1rem;
  line-height: 1.2rem;
  padding-right: 0.2rem;
  opacity: 0.6;
}
.recent-project-bar i.root-199 {
  opacity: 0.9;
  padding-left: 2px;
}
.recent-project-bar .gap-control {
  margin-left: 20px !important;
}
.content-holder {
  width: calc(100% - 30px);
  border: 1px solid #bab8b7;
  margin-top: 15px !important;
}
.content-holder-inner {
  margin-top: 15px !important;
  overflow: hidden;
}
.holder-inner {
  border: 1px solid #d2d2d2;
}
.title-bar-1 {
  border-bottom: 1px solid #bab8b7;
  width: 100%;
  padding: 0.5rem;
}
.title-bar-1 a {
  line-height: 0;
  display: flex;
  align-items: center;
}
.title-bar-1 a:hover,
.title-bar-1 a:hover i {
  text-decoration: none;
}
.title-bar-1 i {
  font-size: 1.4rem;
  line-height: 1.2rem;
  color: #323130;
  padding-right: 0.5rem;
}
.title-bar-1 label {
  padding: 0;
  cursor: pointer;
}
/* match with the current app */
.title-bar-1.title-bar-1.title-bar-inner {
  background-color: #fafafa;
  border-bottom: 1px solid #d2d2d2;
}
.title-bar-1.title-bar-inner,
.title-bar-1.title-bar-inner label {
  cursor: default;
}
.title-bar-1.title-bar-inner label {
  margin-left: 0;
}
.inner-container {
  padding: 0.5rem;
}
.project-detail-list.inner-container {
  padding: 0 0 1rem 1rem;
  width: 80%;
  height: 80vh;
}
.project-detail-list.full-inner-container {
  padding: 0 0 1rem 1rem;
  width: calc(100% - 40px);
  min-height: calc(100vh - 218px) !important;
}
.project-list-header {
  padding: 0.5rem;
  min-height: 46px;
  border-bottom: 1px solid #d2d2d2;
}
.project-list-header.right-panel {
  padding: 0 0.5rem 0 0;
}
/* .project-list-header button,
.ms-CommandBar,
.ms-CommandBar .ms-Button.ms-Button--commandBar {
    background-color: #ffffff !important;
}
.project-list-header button.ms-Button--default {
    background-color: #ffffff !important;
} */
.ms-CommandBar {
  padding-right: 0;
  padding-left: 0;
}
.project-list-grid {
  margin: 0;
}
.filter-panel-left {
  height: 47em;
  background-color: #fafafa;
  /* background-color: #e6e6e6; */
  border-right: 1px solid #d2d2d2;
  transition: all 0.5s ease;
  width: 20%;
  max-width: 189px !important;
  min-width: 189px;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
}
.filter-panel-left.panel-move {
  width: 40px;
  min-width: 40px;
  white-space: nowrap;
}
.filter-panel-left .ms-Label {
  white-space: nowrap;
}
.filter-panel-left .ms-TooltipHost {
  display: none;
}

.ms-Grid-col.padding-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.ms-Grid-col.padding-left-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.grid-righter {
  flex-grow: 1;
  border-right: 1px solid #d2d2d2;
}

/* ------------------------------- */
/* .grid-righter.column-filter-activated .wrapper-46 {
    height: calc(100vh - 269px);
} */
.ms-Grid .grid-righter .ms-DetailsList-headerWrapper,
.grid-righter .ms-DetailsList-contentWrapper {
  position: static;
  transition: all linear 0.2s;
}
.grid-righter.column-filter-activated .ms-DetailsList-headerWrapper,
.grid-righter.column-filter-activated .footer-as-filter {
  position: absolute;
  top: 0;
}
.grid-righter.column-filter-activated .footer-as-filter {
  top: 47px;
}
.grid-righter.column-filter-activated .ms-DetailsList-contentWrapper {
  padding-top: 103px;
}
.ms-Grid .ms-DetailsRow-cell {
  color: #494847;
  font-size: 14px;
}
.ms-Grid .ms-DetailsRow.is-selected .ms-DetailsRow-cell {
  font-weight: bold;
}
/* ------------------------------- */

.chkbox-position {
  margin-top: 33px;
}

.back-arrow,
.fwd-arrow {
  cursor: pointer;
  font-size: 18px;
}
.fwd-arrow {
  display: none;
}
.filter-data {
  margin-top: 0.3rem;
}
.filter-data label {
  padding-bottom: 0.8rem;
}
.filter-data .ms-SearchBox {
  width: 99%;
}

.filter-option-list {
  margin-top: 1.5rem;
  white-space: nowrap;
}
.filter-option-list label {
  padding-bottom: 0.8rem;
}
.filter-option-list button,
.filter-option-list a {
  display: flex;
  align-items: center;
}
.filter-option-list button i,
.filter-option-list a i {
  padding-right: 0.5rem;
}
.filter-option-list button:hover,
.filter-option-list a:hover {
  text-decoration: none;
}
.filter-option-list button i.root-143,
.filter-option-list a i.root-143 {
  font-size: 1.1rem;
  margin-left: -2px;
}
.filter-option-list a span {
  font-size: 14px;
  font-weight: 600;
}
.filter-option-list a i {
  font-size: 15px;
}

.project-detail-list .proj-detail-block {
  width: 100%;
  margin-top: 15px;
}
.project-detail-list .proj-detail-block a {
  width: 100%;
  border-bottom: 1px solid #bab8b7;
  display: block;
}
.project-detail-list .proj-detail-block a,
.project-detail-list .proj-detail-block a label {
  font-size: 1.1rem;
  cursor: default;
}
.project-detail-list .proj-detail-block a:hover,
.project-detail-list .proj-detail-block a:hover label {
  text-decoration: none;
}
.project-detail-list .proj-detail-block .proj-detail-content {
  padding-top: 0.5rem;
}
.project-detail-list .proj-detail-block .proj-detail-content.inner {
  /* width: 80%; */
  width: calc(100% - 20px);
}
.project-detail-list
  .proj-detail-block
  .proj-detail-content.group-on
  .proj-detail-block
  a,
.project-detail-list
  .proj-detail-block
  .proj-detail-content.group-on
  .proj-detail-block
  a
  label {
  opacity: 0.8;
  font-size: 1rem;
}

.ms-Stack.css-168 {
  scroll-behavior: smooth;
}
.bookmark-panel.parent,
.bookmark-panel-responsive {
  width: 210px;
  height: 20rem;
  position: absolute;
  top: 100px;
  right: -1px;
  background-color: #ffffff;
  border: 1px solid #bab8b7;
  /*border-top: 1px solid #bab8b7;*/
  /*border-left: 1px solid #bab8b7;*/
  /*border-bottom: none;*/
  transition: all 0.2s linear;
}
.bookmark-panel.parent {
  width: calc(100% - 80%);
  /*// height: calc(100% - 3.75rem);*/
  height: calc(100vh - 174px);
  top: 43px;
  z-index: 1;
  border: 1px solid #d2d2d2;
  /*border-top: 1px solid #bab8b7;*/
  /*border-left: 1px solid #bab8b7;*/
  /*border-bottom: none;*/
}
.bookmark-panel-responsive {
  top: 46px;
  right: 17px;
  border-top: 0;
}
.bookmark-panel.parent a {
  font-size: 14px !important;
  display: block;
  padding: 1px 0;
}
.bookmark-panel.parent label {
  padding: 1px 0;
}
.bookmark-panel.parent .title {
  font-size: 16px;
  font-weight: bold;
  padding: 0.5rem;
  margin-top: 0;
  display: block;
  background-color: #ffffff;
}
.bookmark-panel.parent .inner-container {
  overflow: auto;
  margin-top: 0;
  padding-top: 0;
}
.bookmark-panel.parent i.ms-Icon--ChromeClose {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  display: block;
}
.bookmark-panel.parent.collapse i.ms-Icon--ChromeClose {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  display: block;
}
.bookmark-panel-responsive {
  width: auto;
  height: auto;
  cursor: pointer;
  padding: 8px 8px 5px;
  display: none;
}
.bookmark-panel-responsive i {
  font-size: 22px;
  /* color: #ee7423; */
}
.bookmark-panel-responsive i:hover {
  color: #854114;
}
.bookmark-panel.parent.panel-on i.ms-Icon--ChromeClose {
  display: none;
  font-size: 14px;
  opacity: 0.5;
}
.bookmark-panel .inner-container a {
  border-left: 4px solid #ffffff;
  padding-left: 10px;
}
.bookmark-panel .inner-container label.selected,
.bookmark-panel .inner-container a.selected {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
}
.bookmark-panel .inner-container label.selected a {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
  color: #854114;
}
.bookmark-panel .inner-container label.selected {
  border-left: 0;
}
.ms-Panel .ms-Panel-content {
  padding: 0;
}
.ms-Panel .project-list-header {
  padding: 0 0.5rem;
  min-height: auto;
}
.ms-Panel .ms-Panel-contentInner .ms-Panel-header {
  position: absolute;
  top: 0;
  width: 80%;
  margin-top: 8px;
}
.custom-detail-panel .ms-Panel-main {
  width: 60%;
}
.ms-Panel .ms-Panel-scrollableContent {
  overflow: hidden !important;
  position: relative;
  height: 100%;
}

/* const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
} */

.filter-panel-left.panel-move .ms-Label {
  visibility: hidden;
  white-space: nowrap;
}
.filter-panel-left.panel-move .ms-SearchBox.txt-filter-1 {
  border-width: 0;
  background-color: transparent;
}
.filter-panel-left.panel-move .ms-SearchBox-field {
  visibility: hidden;
}
.filter-panel-left.panel-move .ms-SearchBox-iconContainer {
  text-align: left;
}
.filter-panel-left.panel-move .back-arrow {
  display: none;
}
.filter-panel-left.panel-move .fwd-arrow {
  display: block;
}
.filter-panel-left.panel-move .filter-option-list button span,
.filter-panel-left.panel-move .filter-option-list a span {
  visibility: hidden;
}
.filter-panel-left.panel-move .filter-option-list button i,
.filter-panel-left.panel-move .filter-option-list a i,
.filter-panel-left.panel-move .ms-SearchBox-icon,
.filter-panel-left .search-icon-only {
  font-size: 20px;
  padding-right: 0;
  padding-left: 2px;
  transition: all 0.2s linear;
  font-weight: normal;
}
.filter-panel-left.panel-move .ms-SearchBox-icon {
  padding-left: 0;
  margin-left: -2px;
}
.filter-panel-left.panel-move .filter-option-list button i.root-143,
.filter-panel-left.panel-move .filter-option-list a i.root-143 {
  font-size: 22px;
}
.filter-panel-left.panel-move .filter-option-list .ms-Stack button {
  margin-bottom: 15px;
}
.filter-panel-left.panel-move
  .ms-SearchBox.txt-filter-1
  .ms-SearchBox-iconContainer {
  cursor: pointer;
}
.filter-panel-left.panel-move .ms-TooltipHost {
  display: inline;
}
.filter-panel-left.panel-move .filter-icon,
.filter-panel-left .search-icon-only,
.filter-panel-left.panel-move .ms-SearchBox.txt-filter-1 {
  display: none;
}
.filter-panel-left.panel-move .search-icon-only {
  display: block;
}
.filter-panel-left .search-icon-only {
  /* color: #ee7423; */
  margin-top: 0.5rem;
  cursor: pointer;
}
.filter-panel-left.panel-move .search-icon-only {
  font-size: 22px;
}
.padding-set-top {
  padding-top: 1rem;
}

/* Fabric ICON */
.filter-panel-left i,
.project-detail-list i {
  font-family: FabricMDL2Icons;
}
.filter-panel-left i.ms-SearchBox-icon {
  text-indent: -200000px;
  position: relative;
  font-size: 18px;
  margin-top: -2px;
}
.filter-panel-left i.ms-SearchBox-icon:before {
  content: '\E721';
  position: absolute;
  left: 5px;
  top: 2px;
  text-indent: 0;
}
i.ms-Icon--POISolid:before {
  content: '\F2D1';
}
i.ms-Icon--Waffle:before {
  content: '\ED89';
}
i.ms-Icon--FolderHorizontal:before {
  content: '\F12B';
}
i.ms-Icon--SelectAll:before {
  content: '\E8A9';
}
i.ms-Icon--GroupedList:before {
  content: '\EF74';
}
i.ms-Icon--Search:before {
  content: '\E721';
}
i.ms-Icon--GotoToday:before {
  content: '\E8D1';
}
i.ms-Icon--CalendarWeek:before {
  content: '\E8C0';
}
i.ms-Icon--CalendarReply:before {
  content: '\E8F5';
}
i.ms-Icon--FavoriteList:before {
  content: '\E728';
}
i.ms-Icon--Completed:before {
  content: '\E930';
}
i.ms-Icon--Back:before {
  content: '\E700';
}
i.ms-Icon--Forward:before {
  content: '\E700';
}
i.ms-Icon.ms-Icon--SearchBookmark:before {
  content: '\F5B8';
}
i.ms-Icon.ms-Icon--ChromeClose:before {
  content: '\E8BB';
}
i.ms-Icon--Flow:before {
  content: '\EF90';
}
i.ms-Icon--Archive:before {
  content: '\F03F';
}
i.ms-Icon--Home:before {
  content: '\E80F';
}

/* ------------ */
.common-commandbar i,
.overflow-control i,
.ms-ContextualMenu i {
  font-family: FabricMDL2Icons;
  font-style: normal;
}
.common-commandbar [data-automation-id='addNewButton'] i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addNewButton'] i:before {
  content: '\E710';
}
.common-commandbar
  [data-automation-id='addUploadButton']
  i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addUploadButton'] i:before {
  content: '\E898';
}
.common-commandbar
  [data-automation-id='addShareButton']
  i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addShareButton'] i:before {
  content: '\E72D';
}
.common-commandbar
  [data-automation-id='addDownloadButton']
  i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addDownloadButton'] i:before {
  content: '\E896';
}
.common-commandbar
  [data-automation-id='addMoveButton']
  i.ms-Button-icon:before {
  content: '\E8DE';
}
.common-commandbar
  [data-automation-id='addCopyButton']
  i.ms-Button-icon:before {
  content: '\E8C8';
}
.common-commandbar [data-automation-id='renameButton'] i.ms-Button-icon:before {
  content: '\E70F';
}
.common-commandbar
  [data-automation-id='addSortButton']
  i.ms-Button-icon:before {
  content: '\E9D0';
}
.common-commandbar
  [data-automation-id='addTilesButton']
  i.ms-Button-icon:before {
  content: '\ECA5';
}
.common-commandbar
  [data-automation-id='addInfoButton']
  i.ms-Button-icon:before {
  content: '\E946';
}

/* ------------ */
.ms-ContextualMenu [data-automation-id='newEmailButton'] i:before {
  content: '\E715';
}
.ms-ContextualMenu
  [data-automation-id='addCalendarButton']
  i.ms-Icon-placeHolder:before {
  content: '\E787';
}
.ms-ContextualMenu
  [data-automation-id='addMoveButton']
  i.ms-Icon-placeHolder:before {
  content: '\E8DE';
}
.ms-ContextualMenu
  [data-automation-id='addCopyButton']
  i.ms-Icon-placeHolder:before {
  content: '\E8C8';
}
.ms-ContextualMenu
  [data-automation-id='renameButton']
  i.ms-Icon-placeHolder:before {
  content: '\E70F';
}

/* ------------ */
.overflow-control
  .ms-OverflowSet-item:first-child
  i[role='presentation'].ms-Icon-placeHolder:before {
  content: '\E710';
}
.overflow-control
  .ms-OverflowSet-item:nth-child(2)
  i[role='presentation'].ms-Icon-placeHolder:before {
  content: '\E898';
}
.overflow-control
  .ms-OverflowSet-item:nth-child(3)
  i[role='presentation'].ms-Icon-placeHolder:before {
  content: '\E72D';
}

/* ------------ */
.common-commandbar {
  width: 100%;
}
/* .common-commandbar .ms-Button:hover,
.overflow-control .ms-Button:hover {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(32, 31, 30);
} */
.overflow-control .ms-Button {
  padding: 14px 10px;
}
.overflow-control,
.accordian-filter-panel .ms-Button {
  position: absolute;
  top: 0;
  right: 2px;
}
.ms-Pivot.ms-Pivot--large.ms-Pivot--tabs .ms-Button {
  display: none !important;
}
.accordian-filter-panel .ms-Button {
  right: auto;
  margin-top: 6px;
}
.accordian-container {
  position: static;
  padding-top: 50px;
}

.common-commandbar .ms-Button-menuIcon,
.ms-OverflowSet i[data-icon-name='ChevronDown'],
.accordian-filter-panel i[data-icon-name='ChevronDown'],
.footer-as-filter i[data-icon-name='ChevronDown'],
.ms-OverflowSet i[data-icon-name='More'],
i[data-icon-name='ChevronRight'].ms-ContextualMenu-submenuIcon,
.ms-Panel-main i[data-icon-name='Cancel'],
.ms-DetailsList-headerWrapper i[data-icon-name='Page'],
.ms-DetailsList-headerWrapper i[data-icon-name='SortUp'] {
  text-indent: -20000000px;
  position: relative;
  color: rgb(243, 242, 241);
}
.common-commandbar i[data-icon-name='More']:before,
.common-commandbar i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='ChevronDown']:before,
.accordian-filter-panel i[data-icon-name='ChevronDown']:before,
.footer-as-filter i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='More']:before,
i[data-icon-name='ChevronRight'].ms-ContextualMenu-submenuIcon:before,
.ms-Panel-main i[data-icon-name='Cancel']:before,
.ms-DetailsList-headerWrapper i[data-icon-name='Page']:before,
.ms-DetailsList-headerWrapper i[data-icon-name='SortUp']:before {
  content: '\E712';
  position: absolute;
  top: 0;
  left: -9px;
  text-indent: 0;
  color: rgb(163, 162, 160);
}
.common-commandbar i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='More']:before {
  content: '\E70D';
  left: -5px;
}
.accordian-filter-panel i[data-icon-name='ChevronDown']:before,
.footer-as-filter i[data-icon-name='ChevronDown']:before {
  content: '\E70D';
  left: -14px;
}
.common-commandbar [data-automation-id='addNewButton'] {
  padding-right: 10px;
}
i[data-icon-name='ChevronRight'].ms-ContextualMenu-submenuIcon:before {
  content: '\E76C';
}
.ms-OverflowSet i[data-icon-name='More']:before {
  content: '\E712';
  left: -6px;
}
.common-commandbar .ms-OverflowSet i[data-icon-name='More']:before {
  content: '\E712';
  left: -9px;
}
.ms-Panel-main i[data-icon-name='Cancel']:before {
  content: '\E8BB';
}
.ms-DetailsList-headerWrapper i[data-icon-name='Page']:before {
  content: '\E7C3';
  left: 0;
}
.ms-DetailsList-headerWrapper i[data-icon-name='SortUp']:before {
  content: '\EE68';
  left: 4px;
}

/* ------------ */
/* ------------ */
@media only screen and (max-width: 1440px) {
  .filter-panel-left .back-arrow,
  .filter-panel-left.panel-move .back-arrow,
  .filter-panel-left .fwd-arrow,
  .filter-panel-left.panel-move .fwd-arrow {
    visibility: hidden;
  }
  .filter-panel-left {
    width: 40px;
    white-space: nowrap;
  }
  .filter-panel-left .ms-Label {
    visibility: hidden;
    white-space: nowrap;
  }
  .filter-panel-left.filter-focus .ms-Label,
  .filter-panel-left.filter-focus .ms-SearchBox-field {
    visibility: visible;
  }
  .filter-panel-left.filter-focus,
  .filter-panel-left:hover {
    width: 30%;
    max-width: 189px !important;
  }
  .filter-panel-left:hover .ms-Label,
  .filter-panel-left:hover .ms-SearchBox-field,
  .filter-panel-left.filter-focus .ms-Label,
  .filter-panel-left.filter-focus .ms-SearchBox-field {
    visibility: visible;
  }

  .filter-panel-left .ms-SearchBox.txt-filter-1 {
    border-width: 0;
    background-color: transparent;
  }
  .filter-panel-left .ms-SearchBox-field {
    visibility: hidden;
  }
  .filter-panel-left .ms-SearchBox-iconContainer {
    text-align: left;
  }
  .filter-panel-left:hover .ms-SearchBox.txt-filter-1,
  .filter-panel-left.filter-focus .ms-SearchBox.txt-filter-1 {
    border-width: 1px;
    background-color: #ffffff;
  }
  .filter-panel-left .filter-option-list button span,
  .filter-panel-left .filter-option-list a span {
    visibility: hidden;
  }
  .filter-panel-left:hover .filter-option-list button span,
  .filter-panel-left.filter-focus .filter-option-list button span,
  .filter-panel-left:hover .filter-option-list a span,
  .filter-panel-left.filter-focus .filter-option-list a span {
    visibility: visible;
  }
  .filter-panel-left .filter-option-list button i,
  .filter-panel-left .filter-option-list a i,
  .filter-panel-left .ms-SearchBox-icon {
    font-size: 20px;
    padding-right: 0;
    padding-left: 2px;
    transition: all 0.2s linear;
    font-weight: normal;
  }
  .filter-panel-left .ms-SearchBox-icon {
    padding-left: 0;
    margin-left: -2px;
  }
  .filter-panel-left .filter-option-list button i.root-143,
  .filter-panel-left .filter-option-list a i.root-143 {
    font-size: 22px;
  }
  .filter-panel-left .filter-option-list .ms-Stack button {
    margin-bottom: 15px;
  }

  .filter-panel-left:hover .filter-option-list button i,
  .filter-panel-left.filter-focus .filter-option-list button i,
  .filter-panel-left:hover .filter-option-list a i,
  .filter-panel-left.filter-focus .filter-option-list a i {
    font-size: 14px;
    padding-right: 0.5rem;
    padding-left: auto;
  }
  .filter-panel-left:hover .filter-option-list button i.root-143,
  .filter-panel-left.filter-focus .filter-option-list button i.root-143,
  .filter-panel-left:hover .filter-option-list a i.root-143,
  .filter-panel-left.filter-focus .filter-option-list a i.root-143 {
    font-size: 16px;
  }
  .filter-panel-left:hover .filter-option-list .ms-Stack button,
  .filter-panel-left.filter-focus .filter-option-list .ms-Stack button {
    margin-bottom: auto;
  }

  .filter-panel-left:hover .ms-SearchBox-icon,
  .filter-panel-left.filter-focus .ms-SearchBox-icon {
    font-size: 16px;
    padding-left: auto;
    margin-left: 8px;
  }
  .filter-panel-left .ms-TooltipHost,
  .filter-panel-left:hover .ms-TooltipHost {
    display: none;
  }
  .filter-panel-left .filter-icon,
  .filter-panel-left:hover .filter-icon {
    display: block;
  }
  .filter-panel-left.panel-move .ms-SearchBox.txt-filter-1 {
    display: block;
  }
  .filter-panel-left i.ms-SearchBox-icon:before {
    left: 0;
  }
  .project-detail-list .proj-detail-block .proj-detail-content.inner {
    width: calc(100% - 20px);
  }
  .bookmark-panel.parent {
    display: none;
  }
  .bookmark-panel-responsive,
  .bookmark-panel.parent.panel-on i.ms-Icon--ChromeClose {
    display: block;
  }
  .bookmark-panel i,
  .bookmark-panel.parent.panel-on {
    display: inline-flex;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1800px) {
  .filter-panel-left {
    width: 25%;
  }
  .project-detail-list.inner-container {
    width: 75%;
  }
  .project-detail-list.vp-inner-container {
    width: 75%;
  }
  .bookmark-panel.parent {
    width: calc(100% - 75%);
  }
}
@media only screen and (max-width: 1000px) {
  .hidden-M {
    display: none !important;
  }
}
@media only screen and (max-width: 1279px) {
  .hidden-L {
    display: none !important;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1440px) {
  .hidden-XL {
    display: none !important;
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1440px) {
  .project-detail-list.inner-container {
    width: 100%;
  }
  .project-detail-list.vp-inner-container {
    width: 100%;
  }
  .ms-Grid-col.ms-lg6.grid-content-left {
    width: 52%;
  }
  .ms-Grid-col.ms-lg6.grid-content-right {
    width: 48%;
  }
}
@media only screen and (max-width: 1100px) {
  .ms-Grid-col.grid-content-left {
    width: 100%;
  }
  .ms-Grid-col.grid-content-right {
    display: none;
  }
  .bookmark-panel.parent {
    top: -1px;
    height: 100%;
  }
  .bookmark-panel-responsive {
    top: 0;
    right: 17px;
  }
  .grid-righter {
    border-right: 0;
  }
}
@media only screen and (max-width: 1440px) {
  .bookmark-panel-responsive {
    width: auto;
  }
  .bookmark-panel.parent {
    width: 220px;
  }
  .filter-panel-left .ms-TooltipHost .search-icon-only {
    display: none;
  }
  .filter-panel-left i.ms-SearchBox-icon {
    font-size: 22px;
    margin-left: -3px;
  }
}

.window-width-indicator {
  position: fixed;
  top: 6px;
  right: 15px;
  color: white;
  font-size: 12px;
  font-weight: normal;
}

.ms-DetailsHeader {
  padding-top: 0 !important;
}

.ms-DetailsList {
  min-height: 100px;
}

.qrListPane {
  height: 100% !important;
}

.is-disabled span i {
  color: #999999 !important;
}

.is-disabled label {
  color: #000000 !important;
}

.ms-Dialog-main {
  overflow: hidden;
}

.reactSelect__placeholder {
  top: 49% !important;
}
.reactSelect__indicator-separator {
  display: none !important;
}

.project-detail-list.inner-container.cpc {
  padding: 0 0 1rem 1rem;
  min-height: calc(100vh - 218px) !important;
}

.project-detail-list.inner-container.cab {
  padding: 0 0 1rem 1rem;
  height: 76vh;
  background-color: #edebe9;
}

.title-pane {
  /* background-color: #f4681d; */
  color: white;
  border-left: 1px solid #ddd;
}

.short-cut-pane-label {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  color: rgb(50, 49, 48);
}

.filter-title .ms-TooltipHost {
  display: block;
}

.card-container {
  background-color: #fff !important;
  width: 100%;
}
.card-footer {
  margin-top: 15px;
}
.document-pane-card {
  width: 90%;
  background: #fff;
  padding: 5px 28px 5px 28px;
  margin-bottom: 28px;
  border-radius: 2px;
  background-clip: padding-box;
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132),
    0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
}
@media only screen and (max-width: 1100px) {
  .document-pane-card {
    width: 85%;
  }
  .card-container {
    background-color: #fff !important;
    width: 100% !important;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1440px) {
  .document-pane-card {
    width: 88%;
  }
}

.document-pane-card.wbs {
  width: auto;
  background: #fff;
  padding: 5px 28px 5px 28px;
  margin-bottom: 28px;
  border-radius: 2px;
  background-clip: padding-box;
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132),
  0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
}

.marginTop {
  margin-top: 10px;
}
.marginBottom {
  margin-bottom: 35px;
}
@media only screen and (max-width: 1100px) {
  .treeStructure-container {
    margin-bottom: 20vh !important;
  }
  .cpc-basic-unit {
    margin-top: 1px !important;
  }
}

.breadcrumb-bar {
  border: 1px solid rgb(96, 94, 92);
  height: 30px;
  margin-top: 0;
  margin-bottom: 5px;
}

@media only screen and (max-width: 1100px) {
  .tree-view-field {
    width: 100%;
  }
}

@media only screen and (max-width: 1280px) {
  .tree-view-field {
    width: 88%;
  }
}
@media only screen and (min-width: 1281px) {
  .tree-view-field {
    width: 92%;
  }
}

a.cke_button {
  display: inline-block !important;
  height: 18px !important;
  padding: 4px 6px !important;
  outline: 0 !important;
  cursor: default !important;
  /* float: left !important; */
  border: 0 !important;
  position: relative !important;
  width: auto !important;
}

a.cke_path_item,
span.cke_path_empty {
  display: inline-block !important;
  /* float: left !important; */
  padding: 3px 4px !important;
  margin-right: 2px !important;
  cursor: default !important;
  text-decoration: none !important;
  outline: 0 !important;
  border: 0 !important;
  color: #484848 !important;
  font-weight: bold !important;
  font-size: 11px !important;
  width: auto !important;
}

.sub-header-title {
  border-bottom: 1px solid #bab8b7;
}

.reactSelect__menu {
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI',
    -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif !important ;
  font-size: 14px !important ;
  font-weight: 400 !important ;
}

.reactSelect__single-value {
  font-size: 14px !important ;
  font-weight: 400 !important ;
}

.costTable_header {
  box-sizing: border-box;
  border-bottom: 1px black solid;
}
.costTable_header_col {
  width: 200px;
}

.progress-statement .ms-DetailsList-headerWrapper .ms-DetailsRow {
  background: #faf9f8;
}

/*collapse BookmarkPane*/
.collapse-bookmark-panel.parent,
.collapse-bookmark-panel-responsive {
  width: 40px;
  height: 20rem;
  position: absolute;
  top: 100px;
  right: -1px;
  background-color: #ffffff;
  border: 1px solid #bab8b7;
  /*border-top: 1px solid #bab8b7;*/
  /*border-left: 1px solid #bab8b7;*/
  /*border-bottom: none;*/
  transition: all 0.2s linear;
}
.collapse-bookmark-panel.parent {
  width: 40px;
  /*// height: calc(100% - 3.75rem);*/
  height: calc(100vh - 174px);
  top: 43px;
  z-index: 1;
  border: 1px solid #d2d2d2;
  /*border-top: 1px solid #bab8b7;*/
  /*border-left: 1px solid #bab8b7;*/
  /*border-bottom: none;*/
}
.collapse-bookmark-panel-responsive {
  top: 46px;
  right: 17px;
  border-top: 0;
}
.collapse-bookmark-panel.parent a {
  font-size: 14px !important;
  display: block;
  padding: 1px 0;
}
.collapse-bookmark-panel.parent label {
  padding: 1px 0;
}
.collapse-bookmark-panel.parent .title {
  font-size: 16px;
  font-weight: bold;
  padding: 0.5rem;
  margin-top: 0;
  display: block;
  background-color: #ffffff;
}
.collapse-bookmark-panel.parent .inner-container {
  overflow: auto;
  margin-top: 0;
  padding-top: 0;
}
.collapse-bookmark-panel.parent i.ms-Icon--ChromeClose {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  display: none;
}
.collapse-bookmark-panel-responsive {
  width: auto;
  height: auto;
  cursor: pointer;
  padding: 8px 8px 5px;
  display: none;
}
.collapse-bookmark-panel-responsive i {
  font-size: 22px;
  /* color: #ee7423; */
}
.collapse-bookmark-panel-responsive i:hover {
  color: #854114;
}
.collapse-bookmark-panel.parent.panel-on i.ms-Icon--ChromeClose {
  display: none;
  font-size: 14px;
  opacity: 0.5;
}
.collapse-bookmark-panel .inner-container a {
  border-left: 4px solid #ffffff;
  padding-left: 10px;
}
.collapse-bookmark-panel .inner-container label.selected,
.collapse-bookmark-panel .inner-container a.selected {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
}
.collapse-bookmark-panel .inner-container label.selected a {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
  color: #854114;
}
.collapse-bookmark-panel .inner-container label.selected {
  border-left: 0;
}

.totalAmountCol {
  text-align: right;
}

.shortCutPaneWrapper {
  min-height: calc(100vh - 129px) !important;
}
.PScostTable .is-row-header {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.subShortCutPane {
  margin-left: 18px;
  margin-bottom: 5px;
}
.filter-panel-left.panel-move .subShortCutPane {
  margin-left: 1px !important;
  margin-bottom: 5px;
}

.splitPopup .ms-Grid .ms-Grid-col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.scrollablePane {
  position: inherit;
  display: grid;
}
.purchase-order .ms-DetailsList-headerWrapper .ms-DetailsRow {
  background: #faf9f8;
}

.PoChoiceGroup .ms-ChoiceFieldGroup-flexContainer {
  display: flex !important;
}
.PoChoiceGroup {
  display: flex !important;
  margin-top: -3px !important;
  min-height: 40px !important;
}
.PoChoiceGroup .ms-ChoiceField-input {
  z-index: 10000000 !important;
}
.PoChoiceGroupProduct .ms-ChoiceFieldGroup-flexContainer {
  display: flex !important;
}
.PoChoiceGroupProduct {
  display: flex !important;
  margin-top: 0px !important;
  min-height: 40px !important;
}
.PoChoiceGroupProduct .ms-ChoiceField-input {
  z-index: 10000000 !important;
}

.poDeliveryRowItem {
  min-height: 30px;
  margin-top: 7px;
}

/*image preview modal*/

/* The Modal (background) */
.imageModal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.imageModal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Add Animation */
.imageModal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.imageModalClose {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.imageModalClose:hover,
.imageModalClose:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .imageModal-content {
    width: 100%;
  }
}

.resourceIcon i {
  color: rgb(255, 77, 77) !important;
}

.ck {
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI',
    -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif !important;
  font-size: 14px !important;
}
.ck-content {
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI',
    -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif !important;
  font-size: 14px !important;
}

.ck-editor__editable {
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI',
    -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif !important;
  font-size: 14px !important;
}

.mail-template-fonts {
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI',
    -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif !important;
}

.PoDatePicker {
  padding-bottom: 0px;
}
.PoUnitPrice {
  padding-bottom: 5px;
}

/* Visual Plan style*/
.ViewContainer {
  list-style: none;
  -ms-box-orient: horizontal;
  justify-content: flex-end;
  align-items: center;
}

.Button {
  background-color: #e7e7e7;
  color: black;
  border: none;
  padding: 7px 16px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}
.Switch {
  margin: 4px 15px;
  font-size: 14px;
  font-family: 'Arial, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue';
  display: flex;
  justify-content: center;
  align-items: center;
}
.Switch_Toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  margin-right: 5px;
}

.Switch_Toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.Slider:before {
  position: absolute;
  content: '';
  height: 21px;
  width: 21px;
  left: 6px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .Slider {
  background-color: #2196f3;
}

input:focus + .Slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .Slider:before {
  transform: translateX(26px);
}

/* Visual Plan style*/

.project-detail-list.full-inner-container.vp {
  padding: 0 0 1rem 1rem;
  width: calc(100% - 40px);
  min-height: calc(33vh) !important;
  height: calc(33vh) !important;
}
.project-detail-list.inner-container.vp {
  padding: 0 0 1rem 1rem;
  min-height: calc(33vh) !important;
  height: calc(33vh) !important;
}
.project-detail-list.vp-full-inner-container {
  padding: 0 0 0 1rem;
  width: calc(100% - 40px);
  border-right: 1px solid #bab8b7;
}
.project-detail-list.vp-inner-container {
  padding: 0 0 0 1rem;
  width: 80%;
  border-right: 1px solid #bab8b7;
}

/*vp BookmarkPane*/

.bookmark-panel-vp.parent,
.bookmark-panel-vp-responsive {
  width: calc(100% - 80%);
  position: absolute;
  top: 100px;
  right: 0px;
  background-color: #ffffff;
  border: none !important;
  /*border-top: 1px solid #bab8b7;*/
  /*border-left: 1px solid #bab8b7;*/
  /*border-bottom: none;*/
  transition: all 0.2s linear;
  height: auto;
}
.bookmark-panel-vp.parent {
  width: calc(100% - 80%);
  /*// height: calc(100% - 3.75rem);*/
  top: 53px;
  z-index: 1;
  border: none !important;
  height: auto;
  /*border-top: 1px solid #bab8b7;*/
  /*border-left: 1px solid #bab8b7;*/
  /*border-bottom: none;*/
}
.bookmark-panel-vp-responsive {
  top: 46px;
  right: 17px;
  border-top: 0;
}
.bookmark-panel-vp.parent a {
  font-size: 14px !important;
  display: block;
  padding: 1px 0;
}
.bookmark-panel-vp.parent label {
  padding: 1px 0;
}
.bookmark-panel-vp.parent .title {
  font-size: 16px;
  font-weight: bold;
  padding: 0.5rem;
  margin-top: 0;
  display: block;
  background-color: #ffffff;
}
.bookmark-panel-vp.parent .inner-container {
  overflow: auto;
  margin-top: 0;
  padding-top: 0;
}
.bookmark-panel-vp.parent i.ms-Icon--ChromeClose {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  display: none;
}
.bookmark-panel-vp.parent.collapse i.ms-Icon--ChromeClose {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  display: block;
}
.bookmark-panel-vp-responsive {
  width: auto;
  height: auto;
  cursor: pointer;
  padding: 8px 8px 5px;
  display: none;
}
.bookmark-panel-vp-responsive i {
  font-size: 22px;
  /* color: #ee7423; */
}
.bookmark-panel-vp-responsive i:hover {
  color: #854114;
}
.bookmark-panel-vp.parent.panel-on i.ms-Icon--ChromeClose {
  display: none;
  font-size: 14px;
  opacity: 0.5;
}
.bookmark-panel-vp.inner-container a {
  border-left: 4px solid #ffffff;
  padding-left: 10px;
}
.bookmark-panel-vp.inner-container label.selected,
.bookmark-panel-vp.inner-container a.selected {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
}
.bookmark-panel-vp.inner-container label.selected a {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
  color: #854114;
}
.bookmark-panel-vp.inner-container label.selected {
  border-left: 0;
}
.inner-container-pmol a {
  border-left: 4px solid #ffffff;
  padding-left: 10px;
}
.bookmark-panel-vp.inner-container-pmol label.selected,
.bookmark-panel-vp.inner-container-pmol a.selected {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
}
.bookmark-panel-vp.inner-container-pmol label.selected a {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
  color: #854114;
}
.bookmark-panel-vp.inner-container-pmol label.selected {
  border-left: 0;
}

.collapse-bookmark-panel-vp.parent,
.collapse-bookmark-panel-vp-responsive {
  width: 40px;
  position: absolute;
  top: 100px;
  right: 0px;
  background-color: #ffffff;
  border: none !important;
  /*border-top: 1px solid #bab8b7;*/
  /*border-left: 1px solid #bab8b7;*/
  /*border-bottom: none;*/
  transition: all 0.2s linear;
  height: auto;
}
.collapse-bookmark-panel-vp.parent {
  width: 40px;
  /*// height: calc(100% - 3.75rem);*/
  top: 53px;
  z-index: 1;
  border: none !important;
  height: auto;
  /*border-top: 1px solid #bab8b7;*/
  /*border-left: 1px solid #bab8b7;*/
  /*border-bottom: none;*/
}
.collapse-bookmark-panel-vp-responsive {
  top: 46px;
  right: 17px;
  border-top: 0;
}
.collapse-bookmark-panel-vp.parent a {
  font-size: 14px !important;
  display: block;
  padding: 1px 0;
}
.collapse-bookmark-panel-vp.parent label {
  padding: 1px 0;
}
.collapse-bookmark-panel-vp.parent .title {
  font-size: 16px;
  font-weight: bold;
  padding: 0.5rem;
  margin-top: 0;
  display: block;
  background-color: #ffffff;
}
.collapse-bookmark-panel-vp.parent .inner-container {
  overflow: auto;
  margin-top: 0;
  padding-top: 0;
}
.collapse-bookmark-panel-vp.parent i.ms-Icon--ChromeClose {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  display: none;
}
.collapse-bookmark-panel-vp-responsive {
  width: auto;
  height: auto;
  cursor: pointer;
  padding: 8px 8px 5px;
  display: none;
}
.collapse-bookmark-panel-vp-responsive i {
  font-size: 22px;
  /* color: #ee7423; */
}
.collapse-bookmark-panel-vp-responsive i:hover {
  color: #854114;
}
.collapse-bookmark-panel-vp.parent.panel-on i.ms-Icon--ChromeClose {
  display: none;
  font-size: 14px;
  opacity: 0.5;
}
.collapse-bookmark-panel-vp.inner-container a {
  border-left: 4px solid #ffffff;
  padding-left: 10px;
}
.collapse-bookmark-panel-vp.inner-container label.selected,
.collapse-bookmark-panel-vp.inner-container a.selected {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
}
.collapse-bookmark-panel-vp.inner-container label.selected a {
  font-weight: bold;
  /* border-left: 4px solid #ee7423; */
  color: #854114;
}
.collapse-bookmark-panel-vp.inner-container label.selected {
  border-left: 0;
}

.splitter {
  width: 100%;
  /*height: 100px;*/
  display: flex;
}

#separator {
  /*cursor: col-resize;*/
  background-color: #aaa;
  /*background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='30'><path d='M2 0 v30 M5 0 v30 M8 0 v30' fill='none' stroke='black'/></svg>");*/
  background-repeat: no-repeat;
  background-position: center;
  height: 10px;
  width: 100%;
  cursor: row-resize;

  /* Prevent the browser's built-in drag from interfering */
  -webkit-user-select: none;
          user-select: none;
}

#first {
  background-color: #dde;
  /*width: 50%;*/
  height: 43%;
  min-width: 10px;
}

#second {
  background-color: #eee;
  /*width: 50%;*/
  height: 43%;
  min-width: 10px;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  transition: all 50ms ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 5px;
  margin: 0 -5px;
  border-left: 2px solid rgba(255, 255, 255, 0);
  border-right: 2px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  width: 11px;
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
.Chart .rst__virtualScrollOverride {
  width: 456px !important;
}
.gantt-module_horizontalContainer_1Ns26 {
  /*height: 630px!important;*/
  min-height: 400px;
}
@media screen and (min-width: 1280px) {
  /*For Big TV's (HD Screens) */
  .gantt-module_horizontalContainer_1Ns26 {
    /*height: auto!important;*/
  }
}

@media screen and (min-width: 1920px) {
  /*For Projectors or Higher Resolution Screens (Full HD)*/
  .gantt-module_horizontalContainer_1Ns26 {
    /*height:300px!important;*/
    /*background: yellow;*/
  }
}
@media screen and (min-width: 1921px) and (max-width: 3839px) {
  /*For 2K Displays (Ultra HD)*/
  .gantt-module_horizontalContainer_1Ns26 {
    /*height: auto!important;*/
    /*background: green;*/
  }
}
@media screen and (min-width: 3840px) {
  /*For 4K Displays (Ultra HD)*/
  .gantt-module_horizontalContainer_1Ns26 {
    /*height: auto!important;*/
    /*background: blue;*/
  }
}

.grid-righter.column-filter-activated.vp {
  width: calc(100vw - 190px) !important;
}

.grid-righter.column-filter-activated.vpWithDoc {
  width: 50vw !important;
}

.project-detail-list.four-pane-full-inner-container {
  padding: 0 0 1rem 1rem;
  width: calc(100%) !important;
  height: 100%;
}

.vpDatePicker .ms-DatePicker {
  width: 200px;
}

.vpDatePicker180 .ms-DatePicker {
  width: 180px;
}

.vertical-scroll-module_scroll_3WGFv {
  overflow: hidden;
}

.full-inner-container.pbs-doc-pane {
  height: calc(100vh - 218px) !important;
}

.projectAccess .ms-Checkbox-text {
  width: 100px;
}

.project_filter_pp .ms-BasePicker-text {
  min-width: 130px;
}

.docPane {
  height: 100% !important;
}

.chart .ReactVirtualized__Grid__innerScrollContainer > div:nth-child(even) {
  background-color: #f5f5f5;
}

.chart .rstcustom__row {
  padding-left: 0px !important;
  flex: 1 1;
}

.chart .rstcustom__expandButton {
  padding-left: 2px !important;
  padding-right: 5px !important;
  width: 40px !important;
}

.chart .rstcustom__collapseButton {
  padding-left: 2px !important;
  margin-right: 5px !important;
  width: 40px !important;
}

.chart .rstcustom__rowLabel {
  padding-right: 0px !important;
}

.chart .ms-TextField-fieldGroup {
  margin-left: -40px !important;
}

.chart .ReactVirtualized__Grid {
  overflow: hidden !important;
}

.chart .rst__virtualScrollOverride {
  border-right: 2px solid #e4e8eb !important;
}

.pmol.project-detail-list.inner-container {
  padding: 0 0 1rem 1rem;
  width: 80%;
  height: 100% !important;
}

.pbs.project-detail-list.inner-container {
  padding: 0 0 1rem 1rem;
  width: 80%;
  height: calc(100vh - 218px);
}

.dayPlanning.filter-panel-left {
  height: 47em;
  background-color: #fafafa;
  /* background-color: #e6e6e6; */
  border-right: 1px solid #d2d2d2;
  transition: all 0.5s ease;
  width: 20%;
  max-width: 255px !important;
  min-width: 255px;
  /* .SplitPane .Pane1 {
  width: 100% !important
} */
  z-index: 11;
}

.projectDayPlanning.filter-panel-left {
  height: 100% !important;
  background-color: #fafafa;
  /* background-color: #e6e6e6; */
  border-right: 1px solid #d2d2d2;
  transition: all 0.5s ease;
  width: 20%;
  max-width: 255px !important;
  min-width: 255px;
}

.timeclock.filter-panel-left {
  height: 47em;
  background-color: #fafafa;
  /* background-color: #e6e6e6; */
  border-right: 1px solid #d2d2d2;
  transition: all 0.5s ease;
  width: 20%;
  max-width: 255px !important;
  min-width: 255px;
  /* .SplitPane .Pane1 {
  width: 100% !important
} */
}

.vp.filter-panel-left {
  background-color: #fafafa;

  border-right: 1px solid #d2d2d2;
  transition: all 0.5s ease;
  width: 15%;
}

.chart .rstcustom__expandButton::before,
.rstcustom__collapseButton::before {
  content: '' !important;
  border: solid #7b7b7b !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  /*margin-left: 10px!important;*/
  -webkit-transform: rotate(-45deg);
}

.chart .rstcustom__expandButton::after {
  content: '' !important;
  display: none;
  border: solid #7b7b7b !important;
  border-width: 0 0 0 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  margin-left: 10px !important;
  -webkit-transform: rotate(-45deg);
}

.cbcTaxonomy .rstcustom__expandButton::before,
.rstcustom__collapseButton::before {
  content: '' !important;
  border: solid #7b7b7b !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  margin-left: 10px !important;
  left: 2px;
}

.chart .rstcustom__collapseButton::before {
  content: '' !important;
  border: solid #7b7b7b !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  /*padding: 3px!important;*/
  margin-left: 10px !important;
  -webkit-transform: rotate(45deg);
}

.chart .rstcustom__expandButton {
  padding-right: 0px !important;
}

.chart .rstcustom__collapseButton::after {
  content: '' !important;
  display: none;
  border: solid #7b7b7b !important;
  border-width: 0 0 0 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  margin-left: 10px !important;
  -webkit-transform: rotate(-45deg);
}

.org .rstcustom__collapseButton::before {
  content: '' !important;
  border: solid #7b7b7b !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  padding-right: 10px;
  margin-left: 10px !important;
  -webkit-transform: rotate(45deg);
}

.org .rstcustom__collapseButton::after {
  content: '' !important;
  display: none;
  border: solid #7b7b7b !important;
  border-width: 0 0 0 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  padding-right: 10px;
  margin-left: 10px !important;
  -webkit-transform: rotate(-45deg);
}

.org .rstcustom__collapseButton {
  content: '' !important;
  display: none;
  border: solid #7b7b7b !important;
  border-width: 0 0 0 0 !important;
  display: inline-block !important;
  padding-right: 15px;
}

.org .rstcustom__expandButton {
  content: '' !important;
  display: none;
  border: solid #7b7b7b !important;
  border-width: 0 0 0 0 !important;
  display: inline-block !important;
  padding-right: 15px;
}

.org .rstcustom__expandButton::after {
  content: '' !important;
  display: none;
  border: solid #7b7b7b !important;
  border-width: 0 0 0 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  padding-right: 10px;
  margin-left: 10px !important;
  -webkit-transform: rotate(-45deg);
}

.org .rstcustom__expandButton::before,
.rstcustom__collapseButton::before {
  content: '' !important;
  border: solid #7b7b7b !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  padding-right: 10px;
  margin-left: 10px !important;
  -webkit-transform: rotate(-45deg);
}

.rstcustom__collapseButton::before {
  content: '' !important;
  border: solid #7b7b7b !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  margin-left: 10px !important;
}

.chart .rstcustom__nodeContent {
  width: 100% !important;
}

.chart .rstcustom__toolbarButton {
  background-color: transparent !important;
}

.chart .rstcustom__rowContents {
  background-color: transparent !important;
}

.chart .rstcustom__rowSearchFocus {
  background-color: #a3e4d7 !important;
  outline: #a3e4d7;
}
.dp-dot {
  width: 24px;
  margin-top: -17px;
  margin-right: -1px;
  float: right;
}
.dp-dot .ms-CommandBar-overflowButton {
  background-color: rgb(13 63 106) !important;
  width: 28px !important;
  min-width: 20px !important;
  padding: 0 !important;
}
.dp-dot .ms-OverflowSet.ms-CommandBar-primaryCommand {
  color: #ffffff;
  width: 24px !important;
  min-width: 20px !important;
  padding: 0 !important;
}

.personSquare {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: white;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 auto 10px;
  position: absolute;
  margin-top: 18px;
  margin-left: 10px;
  z-index: 1;
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI',
    -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif !important;
}

.gantt_ganttVerticalContainer__3R4gT {
  height: 1670px !important;
  overflow-x: scroll !important;
}

.gantt_ganttVerticalContainer__3R4gT
  .gantt4k
  .gantt_horizontalContainer__3cfv6 {
  height: 1670px !important;
  /* width: 100% !important; */
  overflow-x: scroll !important;
}
.resizable-fragment .gantt4k .gantt_horizontalContainer__3cfv6 {
  height: 1670px !important;
  overflow-x: scroll !important;
  overflow-y: scroll !important;
  overflow: hidden !important;
}

.gantt4k {
  height: 1670px !important;
  overflow-x: scroll !important;
  overflow-y: scroll !important;
  overflow: hidden !important;
}

.resizable-fragment .gantt_horizontalContainer__3olJ8 {
  height: 612px !important;
}

.horizontal-scroll_scroll__1TmDb {
  display: none !important;
}

.pdp .ms-Panel-header {
  position: absolute;
  top: 0;
  width: 98% !important;
  margin-top: 8px;
}

.ReactVirtualized__Grid .ReactVirtualized__List .rst__virtualScrollOverride {
  scroll-padding-bottom: 20;
}

.planboard-calender .ms-DatePicker div:nth-child(2) {
  padding: 2px !important;
}

.rfq-mobile-list .ms-DetailsHeader-cellName,
.rfq-mobile-list .ms-DetailsRow-cell {
  font-size: 25px !important;
}

.rfq-mobile .ms-TextField-fieldGroup {
  height: 65px !important;
}

.SplitPane .Resizer {
  z-index: 11 !important;
}

.ciaw-eligibility-list-column {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

 /* table,
th, td {
  border: '2px solid black';
} */

.matrixTable table thead th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
}

.expandRowMT td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;

}

.matrixTable table tbody th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
}

.matrixTable table thead th {
  padding: 3px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: white;
}

.fullWidthWithControlledHeight .reactSelect__menu-list{
  max-height: 100px!important;
}

.tree-view-field-in-modal {
  width: 90%;
}

.tree-aside-content{
  display: flex;
  align-items: center;
  min-width: 60px;
}

.date {
  cursor: pointer;
}
/*.tree-aside-content.Component,.date{*/
/*  min-width: 60px!important;*/
/*}*/
/*.tree-aside-content.Component,.date{*/
/*  min-width: 60px!important;*/
/*}*/



.loading-shimmer {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

.fui-Listbox{
  max-height: 600px;
}


.project-day-planning-shortcut-pane{
  height: 100% !important;
  background-color: #fafafa;
  /* background-color: #e6e6e6; */
  border-right: 1px solid #d2d2d2;
  transition: all 0.5s ease;
  width: 20%;
  max-width: 255px !important;
  min-width: 255px;
  overflow: auto !important;;
}

.filter-panel-left.panel-move .menu-item, .filter-panel-left.panel-move .fui-MenuItem__submenuIndicator{
  display: none;
}
.filter-panel-left.panel-move {
  width: 40px!important;
}

.full-height {
  height: 100%;
}

/*mid term planning organisation taxonomy gantt chart styles*/

#orgGantt .gantt4k {
  height: auto!important;
}

#orgGantt > :first-child {
  height: 100%;
  overflow: hidden;
}

#orgGantt [class^="gantt_wrapper"] {
  height: inherit;
  overflow: scroll;
}

#orgGantt .resizable-fragment {
  /*height: auto;*/
  overflow: hidden;
}

#orgGantt [class^="gantt_wrapper"] > :first-child {
  height: auto!important;
}

#orgGantt [class^="gantt_wrapper"] > :nth-child(2) {
  height: 20px!important;
}

#orgGantt .rst__tree :first-child :first-child  {
  /*overflow: hidden;*/
}

/*mid term planning top taxonomy table*/

#midTermOrgGantt .rst__tree > * > * {
  overflow: hidden;
}

#midTermOrgGantt .rstcustom__expandButton, #midTermOrgGantt .rstcustom__collapseButton {
  z-index: 0;
}


.wbs__command__bar{
  height: 27px;
  border-bottom: 1px solid #d2d2d2;
  background-color: rgb(255, 255, 255);
}
.wbs__form-container{
  height: calc(100% - 28px);
  overflow: auto;
  display: flex;
}

.wbs__card{
  margin: 15px;
  background: #fff;
  padding: 5px 28px 5px 28px;
  border-radius: 2px;
  background-clip: padding-box;
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
}

.ck-small-toolbar .ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items{
  height: 25px!important;;
}
.ck-small-toolbar .ck.ck-button, a.ck.ck-button {
  height: 20px!important;;
  min-height:20px!important;
}
.ck-small-toolbar .ck.ck-icon, .ck.ck-icon * {
  height: 15px!important;
}

.projectSelect [class^="reactSelect__control"] {
  min-height: 32px;
  height: 32px;
}

#validation-label-id {
    font-size: 12px;
    font-weight: 400;
    color: rgb(164, 38, 44);
    display: inline;
    animation-duration: 0.367s;
    animation-fill-mode: both;
}

/*.ms-DetailsList{*/
/*height: calc(100vh);*/
/*}*/

.header-bar {
    background-color: #ee7423;
    border-bottom: 1px solid #854114;
    color: white;
    align-items: center;
}

.header-bar a {
    color: white;
    display: flex;
}

.header-bar a:hover {
    text-decoration: none;
}

.header-bar i {
    font-size: 2rem;
    font-weight: bold;
}

.header-bar i:hover {
    text-decoration: none;
}

.header-bar label {
    color: white;
    font-size: 1.1rem;
    font-weight: normal;
    margin-left: 0.2rem !important;
}

.recent-project-bar {
    background-color: #e6e6e6;
    border-bottom: 1px solid #d2d2d2;
    white-space: nowrap;
}

.recent-project-bar a {
    font-size: 0.8rem;
    color: #323130;
    display: flex;
}

.recent-project-bar i {
    font-size: 1rem;
    line-height: 1.2rem;
    padding-right: 0.2rem;
    opacity: 0.6;
}

.recent-project-bar i.root-199 {
    opacity: 0.9;
    padding-left: 2px;
}

.recent-project-bar .gap-control {
    margin-left: 20px !important;
}

.content-holder {
    width: calc(100% - 30px);
    border: 1px solid #bab8b7;
    margin-top: 15px !important;
}

.content-holder-inner {
    margin-top: 15px !important;
    overflow: hidden;
}

.holder-inner {
    border: 1px solid #d2d2d2;
}

.title-bar-1 {
    border-bottom: 1px solid #bab8b7;
    width: 100%;
    padding: 0.5rem;
}

.title-bar-1 a {
    line-height: 0;
    display: flex;
    align-items: center;
}

.title-bar-1 a:hover,
.title-bar-1 a:hover i {
    text-decoration: none;
}

.title-bar-1 i {
    font-size: 1.4rem;
    line-height: 1.2rem;
    color: #323130;
    padding-right: 0.5rem;
}

.title-bar-1 label {
    padding: 0;
    cursor: pointer;
}

/* match with the current app */
.title-bar-1.title-bar-1.title-bar-inner {
    background-color: #fafafa;
    border-bottom: 1px solid #d2d2d2;
}

.title-bar-1.title-bar-inner,
.title-bar-1.title-bar-inner label {
    cursor: default;
}

.title-bar-1.title-bar-inner label {
    margin-left: 0;
}

.inner-container {
    padding: 0.5rem;
}

.project-detail-list.inner-container {
    padding: 0 0 1rem 1rem;
    width: 80%;
    height: 80vh;
}

.project-list-header {
    padding: 0.5rem;
    min-height: 46px;
    border-bottom: 1px solid #d2d2d2;
}

.project-list-header.right-panel {
    padding: 0 0.5rem 0 0;
}

/* .project-list-header button,
.ms-CommandBar,
.ms-CommandBar .ms-Button.ms-Button--commandBar {
    background-color: #ffffff !important;
}
.project-list-header button.ms-Button--default {
    background-color: #ffffff !important;
} */
.ms-CommandBar {
    padding-right: 0;
    padding-left: 0;
}

.project-list-grid {
    margin: 0;
}

.filter-panel-left {
    height: 47em;
    background-color: #fafafa;
    /* background-color: #e6e6e6; */
    border-right: 1px solid #d2d2d2;
    transition: all 0.5s ease;
    width: 20%;
    max-width: 189px !important;
}

.filter-panel-left.panel-move {
    width: 40px;
    white-space: nowrap;
}

.filter-panel-left .ms-Label {
    white-space: nowrap;
}

.filter-panel-left .ms-TooltipHost {
    display: none;
}

.ms-Grid-col.padding-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.ms-Grid-col.padding-left-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.grid-righter {
    flex-grow: 1;
    border-right: 1px solid #d2d2d2;
}

/* ------------------------------- */
/* .grid-righter.column-filter-activated .wrapper-46 {
    height: calc(100vh - 269px);
} */
.ms-Grid .grid-righter .ms-DetailsList-headerWrapper,
.grid-righter .ms-DetailsList-contentWrapper {
    position: static;
    transition: all linear 0.2s;
}

.grid-righter.column-filter-activated .ms-DetailsList-headerWrapper,
.grid-righter.column-filter-activated .footer-as-filter {
    position: absolute;
    top: 0;
}

.grid-righter.column-filter-activated .footer-as-filter {
    top: 47px;
}

.grid-righter.column-filter-activated .ms-DetailsList-contentWrapper {
    padding-top: 103px;
}

.ms-Grid .ms-DetailsRow-cell {
    color: #494847;
    font-size: 14px;
}

.ms-Grid .ms-DetailsRow.is-selected .ms-DetailsRow-cell {
    font-weight: bold;
}

/* ------------------------------- */

.chkbox-position {
    margin-top: 33px;
}

.back-arrow,
.fwd-arrow {
    cursor: pointer;
    font-size: 18px;
}

.fwd-arrow {
    display: none;
}

.filter-data {
    margin-top: 0.3rem;
}

.filter-data label {
    padding-bottom: 0.8rem;
}

.filter-data .ms-SearchBox {
    width: 99%;
}

.filter-option-list {
    margin-top: 1.5rem;
    white-space: nowrap;
}

.filter-option-list label {
    padding-bottom: 0.8rem;
}

.filter-option-list button,
.filter-option-list a {
    display: flex;
    align-items: center;
}

.filter-option-list button i,
.filter-option-list a i {
    padding-right: 0.5rem;
}

.filter-option-list button:hover,
.filter-option-list a:hover {
    text-decoration: none;
}

.filter-option-list button i.root-143,
.filter-option-list a i.root-143 {
    font-size: 1.1rem;
    margin-left: -2px;
}

.filter-option-list a span {
    font-size: 14px;
    font-weight: 600;
}

.filter-option-list a i {
    font-size: 15px;
}

.project-detail-list .proj-detail-block {
    width: 100%;
    margin-top: 15px;
}

.project-detail-list .proj-detail-block a {
    width: 100%;
    border-bottom: 1px solid #bab8b7;
    display: block;
}

.project-detail-list .proj-detail-block a,
.project-detail-list .proj-detail-block a label {
    font-size: 1.1rem;
    cursor: default;
}

.project-detail-list .proj-detail-block a:hover,
.project-detail-list .proj-detail-block a:hover label {
    text-decoration: none;
}

.project-detail-list .proj-detail-block .proj-detail-content {
    padding-top: 0.5rem;
}

.project-detail-list .proj-detail-block .proj-detail-content.inner {
    /* width: 80%; */
    width: calc(100% - 20px);
}

.project-detail-list
.proj-detail-block
.proj-detail-content.group-on
.proj-detail-block
a,
.project-detail-list
.proj-detail-block
.proj-detail-content.group-on
.proj-detail-block
a
label {
    opacity: 0.8;
    font-size: 1rem;
}

.ms-Stack.css-168 {
    scroll-behavior: smooth;
}

.bookmark-panel.parent,
.bookmark-panel-responsive {
    width: 210px;
    height: 20rem;
    position: absolute;
    top: 100px;
    right: -1px;
    background-color: #ffffff;
    border: 1px solid #bab8b7;
    /*border-top: 1px solid #bab8b7;*/
    /*border-left: 1px solid #bab8b7;*/
    /*border-bottom: none;*/
    transition: all 0.2s linear;
}

.bookmark-panel.parent {
    width: calc(100% - 80%);
    /*// height: calc(100% - 3.75rem);*/
    height: calc(100vh - 174px);
    top: 45px;
    z-index: 1;
    border: 1px solid #d2d2d2;
    /*border-top: 1px solid #bab8b7;*/
    /*border-left: 1px solid #bab8b7;*/
    /*border-bottom: none;*/
}

.bookmark-panel-responsive {
    top: 46px;
    right: 17px;
    border-top: 0;
}

.bookmark-panel.parent a {
    font-size: 14px !important;
    display: block;
    padding: 1px 0;
}

.bookmark-panel.parent label {
    padding: 1px 0;
}

.bookmark-panel.parent .title {
    font-size: 16px;
    font-weight: bold;
    padding: 0.5rem;
    margin-top: 0;
    display: block;
    background-color: #ffffff;
}

.bookmark-panel.parent .inner-container {
    overflow: auto;
    margin-top: 0;
    padding-top: 0;
}

.bookmark-panel.parent i.ms-Icon--ChromeClose {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    display: none;
}

.bookmark-panel-responsive {
    width: auto;
    height: auto;
    cursor: pointer;
    padding: 8px 8px 5px;
    display: none;
}

.bookmark-panel-responsive i {
    font-size: 22px;
    /* color: #ee7423; */
}

.bookmark-panel-responsive i:hover {
    color: #854114;
}

.bookmark-panel.parent.panel-on i.ms-Icon--ChromeClose {
    display: none;
    font-size: 14px;
    opacity: 0.5;
}

.bookmark-panel .inner-container a {
    border-left: 4px solid #ffffff;
    padding-left: 10px;
}

.bookmark-panel .inner-container label.selected,
.bookmark-panel .inner-container a.selected {
    font-weight: bold;
    /* border-left: 4px solid #ee7423; */
}

.bookmark-panel .inner-container label.selected a {
    font-weight: bold;
    /* border-left: 4px solid #ee7423; */
    color: #854114;
}

.bookmark-panel .inner-container label.selected {
    border-left: 0;
}

.ms-Panel .ms-Panel-content {
    padding: 0;
}

.ms-Panel .project-list-header {
    padding: 0 0.5rem;
    min-height: auto;
}

.ms-Panel .ms-Panel-contentInner .ms-Panel-header {
    position: absolute;
    top: 0;
    width: 80%;
    margin-top: 8px;
}

.custom-detail-panel .ms-Panel-main {
    width: 60%;
}

.ms-Panel .ms-Panel-scrollableContent {
    overflow: hidden !important;
    position: relative;
    height: 100%;
}

/* const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
} */

.filter-panel-left.panel-move .ms-Label {
    visibility: hidden;
    white-space: nowrap;
}

.filter-panel-left.panel-move .ms-SearchBox.txt-filter-1 {
    border-width: 0;
    background-color: transparent;
}

.filter-panel-left.panel-move .ms-SearchBox-field {
    visibility: hidden;
}

.filter-panel-left.panel-move .ms-SearchBox-iconContainer {
    text-align: left;
}

.filter-panel-left.panel-move .back-arrow {
    display: none;
}

.filter-panel-left.panel-move .fwd-arrow {
    display: block;
}

.filter-panel-left.panel-move .filter-option-list button span,
.filter-panel-left.panel-move .filter-option-list a span {
    visibility: hidden;
}

.filter-panel-left.panel-move .filter-option-list button i,
.filter-panel-left.panel-move .filter-option-list a i,
.filter-panel-left.panel-move .ms-SearchBox-icon,
.filter-panel-left .search-icon-only {
    font-size: 20px;
    padding-right: 0;
    padding-left: 2px;
    transition: all 0.2s linear;
    font-weight: normal;
}

.filter-panel-left.panel-move .ms-SearchBox-icon {
    padding-left: 0;
    margin-left: -2px;
}

.filter-panel-left.panel-move .filter-option-list button i.root-143,
.filter-panel-left.panel-move .filter-option-list a i.root-143 {
    font-size: 22px;
}

.filter-panel-left.panel-move .filter-option-list .ms-Stack button {
    margin-bottom: 15px;
}

.filter-panel-left.panel-move
.ms-SearchBox.txt-filter-1
.ms-SearchBox-iconContainer {
    cursor: pointer;
}

.filter-panel-left.panel-move .ms-TooltipHost {
    display: inline;
}

.filter-panel-left.panel-move .filter-icon,
.filter-panel-left .search-icon-only,
.filter-panel-left.panel-move .ms-SearchBox.txt-filter-1 {
    display: none;
}

.filter-panel-left.panel-move .search-icon-only {
    display: block;
}

.filter-panel-left .search-icon-only {
    /* color: #ee7423; */
    margin-top: 0.5rem;
    cursor: pointer;
}

.filter-panel-left.panel-move .search-icon-only {
    font-size: 22px;
}

.padding-set-top {
    padding-top: 1rem;
}

/* Fabric ICON */
.filter-panel-left i,
.project-detail-list i {
    font-family: FabricMDL2Icons;
}

.filter-panel-left i.ms-SearchBox-icon {
    text-indent: -200000px;
    position: relative;
    font-size: 18px;
    margin-top: -2px;
}

.filter-panel-left i.ms-SearchBox-icon:before {
    content: '\E721';
    position: absolute;
    left: 5px;
    top: 2px;
    text-indent: 0;
}

i.ms-Icon--POISolid:before {
    content: '\F2D1';
}

i.ms-Icon--Waffle:before {
    content: '\ED89';
}

i.ms-Icon--FolderHorizontal:before {
    content: '\F12B';
}

i.ms-Icon--SelectAll:before {
    content: '\E8A9';
}

i.ms-Icon--GroupedList:before {
    content: '\EF74';
}

i.ms-Icon--Search:before {
    content: '\E721';
}

i.ms-Icon--GotoToday:before {
    content: '\E8D1';
}

i.ms-Icon--CalendarWeek:before {
    content: '\E8C0';
}

i.ms-Icon--CalendarReply:before {
    content: '\E8F5';
}

i.ms-Icon--FavoriteList:before {
    content: '\E728';
}

i.ms-Icon--Completed:before {
    content: '\E930';
}

i.ms-Icon--Back:before {
    content: '\E700';
}

i.ms-Icon--Forward:before {
    content: '\E700';
}

i.ms-Icon.ms-Icon--SearchBookmark:before {
    content: '\F5B8';
}

i.ms-Icon.ms-Icon--ChromeClose:before {
    content: '\E8BB';
}

/* ------------ */
.common-commandbar i,
.overflow-control i,
.ms-ContextualMenu i {
    font-family: FabricMDL2Icons;
    font-style: normal;
}

.common-commandbar [data-automation-id='addNewButton'] i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addNewButton'] i:before {
    content: '\E710';
}

.common-commandbar
[data-automation-id='addUploadButton']
i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addUploadButton'] i:before {
    content: '\E898';
}

.common-commandbar
[data-automation-id='addShareButton']
i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addShareButton'] i:before {
    content: '\E72D';
}

.common-commandbar
[data-automation-id='addDownloadButton']
i.ms-Button-icon:before,
.ms-ContextualMenu [data-automation-id='addDownloadButton'] i:before {
    content: '\E896';
}

.common-commandbar
[data-automation-id='addMoveButton']
i.ms-Button-icon:before {
    content: '\E8DE';
}

.common-commandbar
[data-automation-id='addCopyButton']
i.ms-Button-icon:before {
    content: '\E8C8';
}

.common-commandbar [data-automation-id='renameButton'] i.ms-Button-icon:before {
    content: '\E70F';
}

.common-commandbar
[data-automation-id='addSortButton']
i.ms-Button-icon:before {
    content: '\E9D0';
}

.common-commandbar
[data-automation-id='addTilesButton']
i.ms-Button-icon:before {
    content: '\ECA5';
}

.common-commandbar
[data-automation-id='addInfoButton']
i.ms-Button-icon:before {
    content: '\E946';
}

/* ------------ */
.ms-ContextualMenu [data-automation-id='newEmailButton'] i:before {
    content: '\E715';
}

.ms-ContextualMenu
[data-automation-id='addCalendarButton']
i.ms-Icon-placeHolder:before {
    content: '\E787';
}

.ms-ContextualMenu
[data-automation-id='addMoveButton']
i.ms-Icon-placeHolder:before {
    content: '\E8DE';
}

.ms-ContextualMenu
[data-automation-id='addCopyButton']
i.ms-Icon-placeHolder:before {
    content: '\E8C8';
}

.ms-ContextualMenu
[data-automation-id='renameButton']
i.ms-Icon-placeHolder:before {
    content: '\E70F';
}

/* ------------ */
.overflow-control
.ms-OverflowSet-item:first-child
i[role='presentation'].ms-Icon-placeHolder:before {
    content: '\E710';
}

.overflow-control
.ms-OverflowSet-item:nth-child(2)
i[role='presentation'].ms-Icon-placeHolder:before {
    content: '\E898';
}

.overflow-control
.ms-OverflowSet-item:nth-child(3)
i[role='presentation'].ms-Icon-placeHolder:before {
    content: '\E72D';
}

/* ------------ */
.common-commandbar {
    width: 100%;
}

/* .common-commandbar .ms-Button:hover,
.overflow-control .ms-Button:hover {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(32, 31, 30);
} */
.overflow-control .ms-Button {
    padding: 14px 10px;
}

.overflow-control,
.accordian-filter-panel .ms-Button {
    position: absolute;
    top: 0;
    right: 2px;
}

.ms-Pivot.ms-Pivot--large.ms-Pivot--tabs .ms-Button {
    display: none !important;
}

.accordian-filter-panel .ms-Button {
    right: auto;
    margin-top: 6px;
}

.accordian-container {
    position: static;
    padding-top: 50px;
}

.common-commandbar .ms-Button-menuIcon,
.ms-OverflowSet i[data-icon-name='ChevronDown'],
.accordian-filter-panel i[data-icon-name='ChevronDown'],
.footer-as-filter i[data-icon-name='ChevronDown'],
.ms-OverflowSet i[data-icon-name='More'],
i[data-icon-name='ChevronRight'].ms-ContextualMenu-submenuIcon,
.ms-Panel-main i[data-icon-name='Cancel'],
.ms-DetailsList-headerWrapper i[data-icon-name='Page'],
.ms-DetailsList-headerWrapper i[data-icon-name='SortUp'] {
    text-indent: -20000000px;
    position: relative;
    color: rgb(243, 242, 241);
}

.common-commandbar i[data-icon-name='More']:before,
.common-commandbar i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='ChevronDown']:before,
.accordian-filter-panel i[data-icon-name='ChevronDown']:before,
.footer-as-filter i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='More']:before,
i[data-icon-name='ChevronRight'].ms-ContextualMenu-submenuIcon:before,
.ms-Panel-main i[data-icon-name='Cancel']:before,
.ms-DetailsList-headerWrapper i[data-icon-name='Page']:before,
.ms-DetailsList-headerWrapper i[data-icon-name='SortUp']:before {
    content: '\E712';
    position: absolute;
    top: 0;
    left: -9px;
    text-indent: 0;
    color: rgb(163, 162, 160);
}

.common-commandbar i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='ChevronDown']:before,
.ms-OverflowSet i[data-icon-name='More']:before {
    content: '\E70D';
    left: -5px;
}

.accordian-filter-panel i[data-icon-name='ChevronDown']:before,
.footer-as-filter i[data-icon-name='ChevronDown']:before {
    content: '\E70D';
    left: -14px;
}

.common-commandbar [data-automation-id='addNewButton'] {
    padding-right: 10px;
}

i[data-icon-name='ChevronRight'].ms-ContextualMenu-submenuIcon:before {
    content: '\E76C';
}

.ms-OverflowSet i[data-icon-name='More']:before {
    content: '\E712';
    left: -6px;
}

.common-commandbar .ms-OverflowSet i[data-icon-name='More']:before {
    content: '\E712';
    left: -9px;
}

.ms-Panel-main i[data-icon-name='Cancel']:before {
    content: '\E8BB';
}

.ms-DetailsList-headerWrapper i[data-icon-name='Page']:before {
    content: '\E7C3';
    left: 0;
}

.ms-DetailsList-headerWrapper i[data-icon-name='SortUp']:before {
    content: '\EE68';
    left: 4px;
}

/* ------------ */
/* ------------ */
@media only screen and (max-width: 1440px) {
    .filter-panel-left .back-arrow,
    .filter-panel-left.panel-move .back-arrow,
    .filter-panel-left .fwd-arrow,
    .filter-panel-left.panel-move .fwd-arrow {
        visibility: hidden;
    }

    .filter-panel-left {
        width: 40px;
        white-space: nowrap;
    }

    .filter-panel-left .ms-Label {
        visibility: hidden;
        white-space: nowrap;
    }

    .filter-panel-left.filter-focus .ms-Label,
    .filter-panel-left.filter-focus .ms-SearchBox-field {
        visibility: visible;
    }

    .filter-panel-left.filter-focus,
    .filter-panel-left:hover {
        width: 30%;
        max-width: 189px !important;
    }

    .filter-panel-left:hover .ms-Label,
    .filter-panel-left:hover .ms-SearchBox-field,
    .filter-panel-left.filter-focus .ms-Label,
    .filter-panel-left.filter-focus .ms-SearchBox-field {
        visibility: visible;
    }

    .filter-panel-left .ms-SearchBox.txt-filter-1 {
        border-width: 0;
        background-color: transparent;
    }

    .filter-panel-left .ms-SearchBox-field {
        visibility: hidden;
    }

    .filter-panel-left .ms-SearchBox-iconContainer {
        text-align: left;
    }

    .filter-panel-left:hover .ms-SearchBox.txt-filter-1,
    .filter-panel-left.filter-focus .ms-SearchBox.txt-filter-1 {
        border-width: 1px;
        background-color: #ffffff;
    }

    .filter-panel-left .filter-option-list button span,
    .filter-panel-left .filter-option-list a span {
        visibility: hidden;
    }

    .filter-panel-left:hover .filter-option-list button span,
    .filter-panel-left.filter-focus .filter-option-list button span,
    .filter-panel-left:hover .filter-option-list a span,
    .filter-panel-left.filter-focus .filter-option-list a span {
        visibility: visible;
    }

    .filter-panel-left .filter-option-list button i,
    .filter-panel-left .filter-option-list a i,
    .filter-panel-left .ms-SearchBox-icon {
        font-size: 20px;
        padding-right: 0;
        padding-left: 2px;
        transition: all 0.2s linear;
        font-weight: normal;
    }

    .filter-panel-left .ms-SearchBox-icon {
        padding-left: 0;
        margin-left: -2px;
    }

    .filter-panel-left .filter-option-list button i.root-143,
    .filter-panel-left .filter-option-list a i.root-143 {
        font-size: 22px;
    }

    .filter-panel-left .filter-option-list .ms-Stack button {
        margin-bottom: 15px;
    }

    .filter-panel-left:hover .filter-option-list button i,
    .filter-panel-left.filter-focus .filter-option-list button i,
    .filter-panel-left:hover .filter-option-list a i,
    .filter-panel-left.filter-focus .filter-option-list a i {
        font-size: 14px;
        padding-right: 0.5rem;
        padding-left: auto;
    }

    .filter-panel-left:hover .filter-option-list button i.root-143,
    .filter-panel-left.filter-focus .filter-option-list button i.root-143,
    .filter-panel-left:hover .filter-option-list a i.root-143,
    .filter-panel-left.filter-focus .filter-option-list a i.root-143 {
        font-size: 16px;
    }

    .filter-panel-left:hover .filter-option-list .ms-Stack button,
    .filter-panel-left.filter-focus .filter-option-list .ms-Stack button {
        margin-bottom: auto;
    }

    .filter-panel-left:hover .ms-SearchBox-icon,
    .filter-panel-left.filter-focus .ms-SearchBox-icon {
        font-size: 16px;
        padding-left: auto;
        margin-left: 8px;
    }

    .filter-panel-left .ms-TooltipHost,
    .filter-panel-left:hover .ms-TooltipHost {
        display: none;
    }

    .filter-panel-left .filter-icon,
    .filter-panel-left:hover .filter-icon {
        display: block;
    }

    .filter-panel-left.panel-move .ms-SearchBox.txt-filter-1 {
        display: block;
    }

    .filter-panel-left i.ms-SearchBox-icon:before {
        left: 0;
    }

    .project-detail-list .proj-detail-block .proj-detail-content.inner {
        width: calc(100% - 20px);
    }

    .bookmark-panel.parent {
        display: none;
    }

    .bookmark-panel-responsive,
    .bookmark-panel.parent.panel-on i.ms-Icon--ChromeClose {
        display: block;
    }

    .bookmark-panel i,
    .bookmark-panel.parent.panel-on {
        display: inline-flex;
    }
}

@media only screen and (min-width: 1440px) and (max-width: 1800px) {
    .filter-panel-left {
        width: 25%;
    }

    .project-detail-list.inner-container {
        width: 75%;
    }

    .bookmark-panel.parent {
        width: calc(100% - 75%);
    }
}

@media only screen and (max-width: 1000px) {
    .hidden-M {
        display: none !important;
    }
}

@media only screen and (max-width: 1279px) {
    .hidden-L {
        display: none !important;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1440px) {
    .hidden-XL {
        display: none !important;
    }
}

@media only screen and (min-width: 1100px) and (max-width: 1440px) {
    .project-detail-list.inner-container {
        width: 100%;
    }

    .ms-Grid-col.ms-lg6.grid-content-left {
        width: 52%;
    }

    .ms-Grid-col.ms-lg6.grid-content-right {
        width: 48%;
    }
}

@media only screen and (max-width: 1100px) {
    .ms-Grid-col.grid-content-left {
        width: 100%;
    }

    .ms-Grid-col.grid-content-right {
        display: none;
    }

    .bookmark-panel.parent {
        top: -1px;
        height: 100%;
    }

    .bookmark-panel-responsive {
        top: 0;
        right: 17px;
    }

    .grid-righter {
        border-right: 0;
    }
}

@media only screen and (max-width: 1440px) {
    .bookmark-panel-responsive {
        width: auto;
    }

    .bookmark-panel.parent {
        width: 220px;
    }

    .filter-panel-left .ms-TooltipHost .search-icon-only {
        display: none;
    }

    .filter-panel-left i.ms-SearchBox-icon {
        font-size: 22px;
        margin-left: -3px;
    }
}

.window-width-indicator {
    position: fixed;
    top: 6px;
    right: 15px;
    color: white;
    font-size: 12px;
    font-weight: normal;
}

.ms-DetailsHeader {
    padding-top: 0 !important;
}

.ms-DetailsList {
    min-height: 100px;
}

.qrListPane {
    height: 100% !important;
}

.is-disabled span i {
    color: #999999 !important;
}

.is-disabled label {
    color: #000000 !important;
}

.ms-Dialog-main {
    overflow: hidden;
}

.reactSelect__placeholder {
    top: 49% !important;
}

.reactSelect__indicator-separator {
    display: none !important;
}

.project-detail-list.inner-container.cpc {
    padding: 0 0 1rem 1rem;
    min-height: calc(100vh - 218px) !important;
}

.project-detail-list.inner-container.cab {
    padding: 0 0 1rem 1rem;
    min-height: calc(100vh - 218px) !important;
}

.project-detail-list.inner-container.withoutTitlePane {
    padding: 0 0 1rem 1rem;
    min-height: calc(100vh - 185px) !important;
}

.title-pane {
    /* background-color: #f4681d; */
    color: white;
    border-left: 1px solid #ddd;
}

.ck-editor__editable {
    min-height: 180px !important;
}

/* .ms-Suggestions .ms-SearchMore-button {
  display: none !important;
} */

.breadcrumb-bar {
    border: 1px solid rgb(96, 94, 92);
    height: 30px;
    margin-top: 0;
    margin-bottom: 5px;
}

@media only screen and (max-width: 1100px) {
    .resource-family {
        width: 100%;
    }
}

@media only screen and (max-width: 1280px) {
    .resource-family {
        width: 90%;
    }
}

@media only screen and (min-width: 1281px) {
    .resource-family {
        width: 94%;
    }
}

/**
 * Extra class applied to VirtualScroll through className prop
 */
:root {
    --bottom-height: '100%';
}

.rst__virtualScrollOverride {
    overflow: auto !important;
}

.rst__virtualScrollOverride * {
    box-sizing: border-box;
}

.ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
}

.rst__rtl .ReactVirtualized__Grid__innerScrollContainer {
    direction: rtl;
}

.ReactVirtualized__Grid {
    outline: none;
}

.rst__node {
    min-width: 100%;
    white-space: nowrap;
    position: relative;
    text-align: left;
}

.rst__node.rst__rtl {
    text-align: right;
}

.rst__nodeContent {
    position: absolute;
    top: 0;
    bottom: 0;
}

/* ==========================================================================
   Scaffold

    Line-overlaid blocks used for showing the tree structure
   ========================================================================== */
.rst__lineBlock,
.rst__absoluteLineBlock {
    height: 100%;
    position: relative;
    display: inline-block;
}

.rst__absoluteLineBlock {
    position: absolute;
    top: 0;
}

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
    position: absolute;
    content: '';
    background-color: black;
}

/**
 * +-----+
 * |     |
 * |  +--+
 * |     |
 * +-----+
 */
.rst__lineHalfHorizontalRight::before {
    height: 1px;
    top: 50%;
    right: 0;
    width: 50%;
}

.rst__rtl.rst__lineHalfHorizontalRight::before {
    left: 0;
    right: initial;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
    width: 1px;
    left: 50%;
    top: 0;
    height: 100%;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.rst__rtl.rst__lineFullVertical::after,
.rst__rtl.rst__lineHalfVerticalTop::after,
.rst__rtl.rst__lineHalfVerticalBottom::after {
    right: 50%;
    left: initial;
}

/**
 * +-----+
 * |  |  |
 * |  +  |
 * |     |
 * +-----+
 */
.rst__lineHalfVerticalTop::after {
    height: 50%;
}

/**
 * +-----+
 * |     |
 * |  +  |
 * |  |  |
 * +-----+
 */
.rst__lineHalfVerticalBottom::after {
    top: auto;
    bottom: 0;
    height: 50%;
}

/* Highlight line for pointing to dragged row destination
   ========================================================================== */
/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.rst__highlightLineVertical {
    z-index: 3;
}

.rst__highlightLineVertical::before {
    position: absolute;
    content: '';
    background-color: #36c2f6;
    width: 8px;
    margin-left: -4px;
    left: 50%;
    top: 0;
    height: 100%;
}

.rst__rtl.rst__highlightLineVertical::before {
    margin-left: initial;
    margin-right: -4px;
    left: initial;
    right: 50%;
}

@keyframes arrow-pulse {
    0% {
        transform: translate(0, 0);
        opacity: 0;
    }
    30% {
        transform: translate(0, 300%);
        opacity: 1;
    }
    70% {
        transform: translate(0, 700%);
        opacity: 1;
    }
    100% {
        transform: translate(0, 1000%);
        opacity: 0;
    }
}

.rst__highlightLineVertical::after {
    content: '';
    position: absolute;
    height: 0;
    margin-left: -4px;
    left: 50%;
    top: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid white;
    animation: arrow-pulse 1s infinite linear both;
}

.rst__rtl.rst__highlightLineVertical::after {
    margin-left: initial;
    margin-right: -4px;
    right: 50%;
    left: initial;
}

/**
 * +-----+
 * |     |
 * |  +--+
 * |  |  |
 * +--+--+
 */
.rst__highlightTopLeftCorner::before {
    z-index: 3;
    content: '';
    position: absolute;
    border-top: solid 8px #36c2f6;
    border-left: solid 8px #36c2f6;
    box-sizing: border-box;
    height: calc(50% + 4px);
    top: 50%;
    margin-top: -4px;
    right: 0;
    width: calc(50% + 4px);
}

.rst__rtl.rst__highlightTopLeftCorner::before {
    border-right: solid 8px #36c2f6;
    border-left: none;
    left: 0;
    right: initial;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  +->|
 * +-----+
 */
.rst__highlightBottomLeftCorner {
    z-index: 3;
}

.rst__highlightBottomLeftCorner::before {
    content: '';
    position: absolute;
    border-bottom: solid 8px #36c2f6;
    border-left: solid 8px #36c2f6;
    box-sizing: border-box;
    height: calc(100% + 4px);
    top: 0;
    right: 12px;
    width: calc(50% - 8px);
}

.rst__rtl.rst__highlightBottomLeftCorner::before {
    border-right: solid 8px #36c2f6;
    border-left: none;
    left: 12px;
    right: initial;
}

.rst__highlightBottomLeftCorner::after {
    content: '';
    position: absolute;
    height: 0;
    right: 0;
    top: 100%;
    margin-top: -12px;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid #36c2f6;
}

.rst__rtl.rst__highlightBottomLeftCorner::after {
    left: 0;
    right: initial;
    border-right: 12px solid #36c2f6;
    border-left: none;
}

.rst__rowWrapper {
    padding: 10px 10px 10px 0;
    height: 100%;
    box-sizing: border-box;
}

.rst__rtl.rst__rowWrapper {
    padding: 10px 0 10px 10px;
}

.rst__row {
    height: 100%;
    white-space: nowrap;
    display: flex;
    cursor: pointer;
}

.rst__row > * {
    box-sizing: border-box;
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.rst__rowLandingPad,
.rst__rowCancelPad {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.rst__rowLandingPad > *,
.rst__rowCancelPad > * {
    opacity: 0 !important;
}

.rst__rowLandingPad::before,
.rst__rowCancelPad::before {
    background-color: lightblue;
    border: 3px dashed white;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.rst__rowCancelPad::before {
    background-color: #e6a8ad;
}

/**
 * Nodes matching the search conditions are highlighted
 */
.rst__rowSearchMatch {
    outline: solid 3px #f4b357;
}

/**
 * The node that matches the search conditions and is currently focused
 */
.rst__rowSearchFocus {
    outline: solid 3px #E73038;
}

.rst__rowContents,
.rst__rowLabel,
.rst__rowToolbar,
.rst__moveHandle,
.rst__toolbarButton {
    display: inline-block;
    vertical-align: middle;
}

.rst__rowContents {
    position: relative;
    height: 100%;
    border: solid #605e5c 1px;
    border-left: none;
    /*box-shadow: 0 2px 2px -2px;*/
    padding: 0 5px 0 10px;
    border-radius: 2px;
    min-width: 230px;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
}

.rst__rtl.rst__rowContents {
    border-right: none;
    border-left: solid #bbb 1px;
    padding: 0 10px 0 5px;
}

.rst__rowContentsDragDisabled {
    border-left: solid #bbb 1px;
}

.rst__rtl.rst__rowContentsDragDisabled {
    border-right: solid #bbb 1px;
    border-left: solid #bbb 1px;
}

.rst__rowLabel {
    flex: 0 1 auto;
    padding-right: 20px;
}

.rst__rtl.rst__rowLabel {
    padding-left: 20px;
    padding-right: inherit;
}

.rst__rowToolbar {
    flex: 0 1 auto;
    display: flex;
}

.rst__moveHandle,
.rst__loadingHandle {
    height: 100%;
    width: 44px;
    background: #d9d9d9 url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+') no-repeat center;
    border: solid #aaa 1px;
    /*box-shadow: 0 2px 2px -2px;*/
    cursor: move;
    border-radius: 1px;
    z-index: 1;
}

.rst__loadingHandle {
    cursor: default;
    background: #d9d9d9;
}

@keyframes pointFade {
    0%,
    19.999%,
    100% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
}

.rst__loadingCircle {
    width: 80%;
    height: 80%;
    margin: 10%;
    position: relative;
}

.rst__loadingCirclePoint {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.rst__rtl.rst__loadingCirclePoint {
    right: 0;
    left: initial;
}

.rst__loadingCirclePoint::before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 11%;
    height: 30%;
    background-color: #fff;
    border-radius: 30%;
    animation: pointFade 800ms infinite ease-in-out both;
}

.rst__loadingCirclePoint:nth-of-type(1) {
    transform: rotate(0deg);
}

.rst__loadingCirclePoint:nth-of-type(7) {
    transform: rotate(180deg);
}

.rst__loadingCirclePoint:nth-of-type(1)::before,
.rst__loadingCirclePoint:nth-of-type(7)::before {
    animation-delay: -800ms;
}

.rst__loadingCirclePoint:nth-of-type(2) {
    transform: rotate(30deg);
}

.rst__loadingCirclePoint:nth-of-type(8) {
    transform: rotate(210deg);
}

.rst__loadingCirclePoint:nth-of-type(2)::before,
.rst__loadingCirclePoint:nth-of-type(8)::before {
    animation-delay: -666ms;
}

.rst__loadingCirclePoint:nth-of-type(3) {
    transform: rotate(60deg);
}

.rst__loadingCirclePoint:nth-of-type(9) {
    transform: rotate(240deg);
}

.rst__loadingCirclePoint:nth-of-type(3)::before,
.rst__loadingCirclePoint:nth-of-type(9)::before {
    animation-delay: -533ms;
}

.rst__loadingCirclePoint:nth-of-type(4) {
    transform: rotate(90deg);
}

.rst__loadingCirclePoint:nth-of-type(10) {
    transform: rotate(270deg);
}

.rst__loadingCirclePoint:nth-of-type(4)::before,
.rst__loadingCirclePoint:nth-of-type(10)::before {
    animation-delay: -400ms;
}

.rst__loadingCirclePoint:nth-of-type(5) {
    transform: rotate(120deg);
}

.rst__loadingCirclePoint:nth-of-type(11) {
    transform: rotate(300deg);
}

.rst__loadingCirclePoint:nth-of-type(5)::before,
.rst__loadingCirclePoint:nth-of-type(11)::before {
    animation-delay: -266ms;
}

.rst__loadingCirclePoint:nth-of-type(6) {
    transform: rotate(150deg);
}

.rst__loadingCirclePoint:nth-of-type(12) {
    transform: rotate(330deg);
}

.rst__loadingCirclePoint:nth-of-type(6)::before,
.rst__loadingCirclePoint:nth-of-type(12)::before {
    animation-delay: -133ms;
}

.rst__loadingCirclePoint:nth-of-type(7) {
    transform: rotate(180deg);
}

.rst__loadingCirclePoint:nth-of-type(13) {
    transform: rotate(360deg);
}

.rst__loadingCirclePoint:nth-of-type(7)::before,
.rst__loadingCirclePoint:nth-of-type(13)::before {
    animation-delay: 0ms;
}

.rst__rowTitle {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
}

.rst__rowTitleWithSubtitle {
    font-size: 85%;
    display: block;
    height: 0.8rem;
}

.rst__rowSubtitle {
    font-size: 70%;
    line-height: 1;
}

.rst__collapseButton,
.rst__expandButton {
    -webkit-appearance: none;
            appearance: none;
    border: none;
    position: absolute;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #000;
    width: 16px;
    height: 16px;
    padding: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.rst__rtl.rst__collapseButton,
.rst__rtl.rst__expandButton {
    transform: translate(50%, -50%);
}

.rst__collapseButton:focus,
.rst__expandButton:focus {
    outline: none;
    box-shadow: 0 0 0 1px #000, 0 0 1px 3px #83bef9;
}

.rst__collapseButton:hover:not(:active),
.rst__expandButton:hover:not(:active) {
    background-size: 24px;
    height: 20px;
    width: 20px;
}

.rst__collapseButton {
    background: #fff url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==') no-repeat center;
}

.rst__expandButton {
    background: #fff url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48cGF0aCBkPSJNOSA0LjV2OSIvPjwvZz4KPC9zdmc+') no-repeat center;
}

/**
 * Line for under a node with children
 */
.rst__lineChildren {
    height: 100%;
    display: inline-block;
    position: absolute;
}

.rst__lineChildren::after {
    content: '';
    position: absolute;
    background-color: black;
    width: 1px;
    left: 50%;
    bottom: 0;
    height: 10px;
}

.rst__rtl.rst__lineChildren::after {
    right: 50%;
    left: initial;
}

.rst__placeholder {
    position: relative;
    height: 68px;
    max-width: 300px;
    padding: 10px;
}

.rst__placeholder,
.rst__placeholder > * {
    box-sizing: border-box;
}

.rst__placeholder::before {
    border: 3px dashed #d9d9d9;
    content: '';
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    z-index: -1;
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.rst__placeholderLandingPad,
.rst__placeholderCancelPad {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.rst__placeholderLandingPad *,
.rst__placeholderCancelPad * {
    opacity: 0 !important;
}

.rst__placeholderLandingPad::before,
.rst__placeholderCancelPad::before {
    background-color: lightblue;
    border-color: white;
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.rst__placeholderCancelPad::before {
    background-color: #e6a8ad;
}

/* Collection default theme */

.ReactVirtualized__Collection {
}

.ReactVirtualized__Collection__innerScrollContainer {
}

/* Grid default theme */

.ReactVirtualized__Grid {
}

.ReactVirtualized__Grid__innerScrollContainer {
}

/* Table default theme */

.ReactVirtualized__Table {
}

.ReactVirtualized__Table__Grid {
}

.ReactVirtualized__Table__headerRow {
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ReactVirtualized__Table__row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ReactVirtualized__Table__headerTruncatedText {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
    margin-right: 10px;
    min-width: 0px;
}

.ReactVirtualized__Table__rowColumn {
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 10px;
}

.ReactVirtualized__Table__sortableHeaderColumn {
    cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
    display: flex;
    align-items: center;
}

.ReactVirtualized__Table__sortableHeaderIcon {
    flex: 0 0 24px;
    height: 1em;
    width: 1em;
    fill: currentColor;
}

/* List default theme */

.ReactVirtualized__List {
}

.selected-node {
    background-color: #999999 !important;
}

.orgSetting .rst__tree {
    height: 88% !important;
}

.org .rstcustom__collapseButton::before {
    content: "" !important;
    border: solid #7b7b7b!important;
    border-width: 0 2px 2px 0!important;
    display: inline-block!important;
    padding: 3px!important;
    padding-right: 10px;
    margin-left: 10px!important;
    -webkit-transform: rotate(45deg);
  }
  
  .org .rstcustom__collapseButton::after {
    content: "" !important;
    display: none;
    border: solid #7b7b7b!important;
    border-width: 0 0 0 0!important;
    display: inline-block!important;
    padding: 3px!important;
    padding-right: 10px;
    margin-left: 10px!important;
    -webkit-transform: rotate(-45deg);
  }
  
  .org .rstcustom__collapseButton {
    content: "" !important;
    display: none;
    border: solid #7b7b7b!important;
    border-width: 0 0 0 0!important;
    display: inline-block!important;
    padding-right: 15px;
    
  }
  
  .org .rstcustom__expandButton {
    content: "" !important;
    display: none;
    border: solid #7b7b7b!important;
    border-width: 0 0 0 0!important;
    display: inline-block!important;
    padding-right: 15px;
    
  }
  
  .org .rstcustom__expandButton::after {
    content: "" !important;
    display: none;
    border: solid #7b7b7b!important;
    border-width: 0 0 0 0!important;
    display: inline-block!important;
    padding: 3px!important;
    padding-right: 10px;
    margin-left: 10px!important;
    -webkit-transform: rotate(-45deg);
  }
  
  .org .rstcustom__expandButton::before, .rstcustom__collapseButton::before {
    content: "" !important;
    border: solid #7b7b7b!important;
    border-width: 0 2px 2px 0!important;
    display: inline-block!important;
    padding: 3px!important;
    padding-right: 10px;
    margin-left: 10px!important;
    -webkit-transform: rotate(-45deg);
  }

  .rstcustom__expandButton {
    /*left: 10.5px !important;*/
  }


.planboard .orgSetting .rst__tree {
    height: var(--bottom-height) !important;
}

/**
 * Extra class applied to VirtualScroll through className prop
 */
.rst__virtualScrollOverride {
  overflow: auto !important;
}
.rst__virtualScrollOverride * {
  box-sizing: border-box;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.rst__rtl .ReactVirtualized__Grid__innerScrollContainer {
  direction: rtl;
}

.ReactVirtualized__Grid {
  outline: none;
}

.rst__node {
  min-width: 100%;
  white-space: nowrap;
  position: relative;
  text-align: left;
}

.rst__node.rst__rtl {
  text-align: right;
}

.rst__nodeContent {
  position: absolute;
  top: 0;
  bottom: 0;
}

/* ==========================================================================
   Scaffold

    Line-overlaid blocks used for showing the tree structure
   ========================================================================== */
.rst__lineBlock,
.rst__absoluteLineBlock {
  height: 100%;
  position: relative;
  display: inline-block;
}

.rst__absoluteLineBlock {
  position: absolute;
  top: 0;
}

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  position: absolute;
  content: '';
  background-color: black;
}

/**
 * +-----+
 * |     |
 * |  +--+
 * |     |
 * +-----+
 */
.rst__lineHalfHorizontalRight::before {
  height: 1px;
  top: 50%;
  right: 0;
  width: 50%;
}

.rst__rtl.rst__lineHalfHorizontalRight::before {
  left: 0;
  right: initial;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  width: 1px;
  left: 50%;
  top: 0;
  height: 100%;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.rst__rtl.rst__lineFullVertical::after,
.rst__rtl.rst__lineHalfVerticalTop::after,
.rst__rtl.rst__lineHalfVerticalBottom::after {
  right: 50%;
  left: initial;
}

/**
 * +-----+
 * |  |  |
 * |  +  |
 * |     |
 * +-----+
 */
.rst__lineHalfVerticalTop::after {
  height: 50%;
}

/**
 * +-----+
 * |     |
 * |  +  |
 * |  |  |
 * +-----+
 */
.rst__lineHalfVerticalBottom::after {
  top: auto;
  bottom: 0;
  height: 50%;
}

/* Highlight line for pointing to dragged row destination
   ========================================================================== */
/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.rst__highlightLineVertical {
  z-index: 3;
}
.rst__highlightLineVertical::before {
  position: absolute;
  content: '';
  background-color: #36c2f6;
  width: 8px;
  margin-left: -4px;
  left: 50%;
  top: 0;
  height: 100%;
}

.rst__rtl.rst__highlightLineVertical::before {
  margin-left: initial;
  margin-right: -4px;
  left: initial;
  right: 50%;
}

@keyframes arrow-pulse {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  30% {
    transform: translate(0, 300%);
    opacity: 1;
  }
  70% {
    transform: translate(0, 700%);
    opacity: 1;
  }
  100% {
    transform: translate(0, 1000%);
    opacity: 0;
  }
}
.rst__highlightLineVertical::after {
  content: '';
  position: absolute;
  height: 0;
  margin-left: -4px;
  left: 50%;
  top: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid white;
  animation: arrow-pulse 1s infinite linear both;
}

.rst__rtl.rst__highlightLineVertical::after {
  margin-left: initial;
  margin-right: -4px;
  right: 50%;
  left: initial;
}

/**
 * +-----+
 * |     |
 * |  +--+
 * |  |  |
 * +--+--+
 */
.rst__highlightTopLeftCorner::before {
  z-index: 3;
  content: '';
  position: absolute;
  border-top: solid 8px #36c2f6;
  border-left: solid 8px #36c2f6;
  box-sizing: border-box;
  height: calc(50% + 4px);
  top: 50%;
  margin-top: -4px;
  right: 0;
  width: calc(50% + 4px);
}

.rst__rtl.rst__highlightTopLeftCorner::before {
  border-right: solid 8px #36c2f6;
  border-left: none;
  left: 0;
  right: initial;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  +->|
 * +-----+
 */
.rst__highlightBottomLeftCorner {
  z-index: 3;
}
.rst__highlightBottomLeftCorner::before {
  content: '';
  position: absolute;
  border-bottom: solid 8px #36c2f6;
  border-left: solid 8px #36c2f6;
  box-sizing: border-box;
  height: calc(100% + 4px);
  top: 0;
  right: 12px;
  width: calc(50% - 8px);
}

.rst__rtl.rst__highlightBottomLeftCorner::before {
  border-right: solid 8px #36c2f6;
  border-left: none;
  left: 12px;
  right: initial;
}

.rst__highlightBottomLeftCorner::after {
  content: '';
  position: absolute;
  height: 0;
  right: 0;
  top: 100%;
  margin-top: -12px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #36c2f6;
}

.rst__rtl.rst__highlightBottomLeftCorner::after {
  left: 0;
  right: initial;
  border-right: 12px solid #36c2f6;
  border-left: none;
}

.rst__rowWrapper {
  padding: 10px 10px 10px 0;
  height: 100%;
  box-sizing: border-box;
}

.rst__rtl.rst__rowWrapper {
  padding: 10px 0 10px 10px;
}

.rst__row {
  height: 100%;
  white-space: nowrap;
  display: flex;
  cursor: pointer;
}
.rst__row > * {
  box-sizing: border-box;
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.rst__rowLandingPad,
.rst__rowCancelPad {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.rst__rowLandingPad > *,
.rst__rowCancelPad > * {
  opacity: 0 !important;
}
.rst__rowLandingPad::before,
.rst__rowCancelPad::before {
  background-color: lightblue;
  border: 3px dashed white;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.rst__rowCancelPad::before {
  background-color: #e6a8ad;
}

/**
 * Nodes matching the search conditions are highlighted
 */
.rst__rowSearchMatch {
  outline: solid 3px #f4b357;
}

/**
 * The node that matches the search conditions and is currently focused
 */
.rst__rowSearchFocus {
  outline: solid 3px #f4681d;
}

.rst__rowContents,
.rst__rowLabel,
.rst__rowToolbar,
.rst__moveHandle,
.rst__toolbarButton {
  display: inline-block;
  vertical-align: middle;
}

.rst__rowContents {
  position: relative;
  height: 100%;
  border: solid #605e5c 1px;
  border-left: none;
  /*box-shadow: 0 2px 2px -2px;*/
  padding: 0 5px 0 10px;
  border-radius: 2px;
  min-width: 230px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

.rst__rtl.rst__rowContents {
  border-right: none;
  border-left: solid #bbb 1px;
  padding: 0 10px 0 5px;
}

.rst__rowContentsDragDisabled {
  border-left: solid #bbb 1px;
}

.rst__rtl.rst__rowContentsDragDisabled {
  border-right: solid #bbb 1px;
  border-left: solid #bbb 1px;
}

.rst__rowLabel {
  flex: 0 1 auto;
  padding-right: 20px;
}
.rst__rtl.rst__rowLabel {
  padding-left: 20px;
  padding-right: inherit;
}

.rst__rowToolbar {
  flex: 0 1 auto;
  display: flex;
}

.rst__moveHandle,
.rst__loadingHandle {
  height: 100%;
  width: 44px;
  background: #d9d9d9
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+')
    no-repeat center;
  border: solid #aaa 1px;
  /*box-shadow: 0 2px 2px -2px;*/
  cursor: move;
  border-radius: 1px;
  z-index: 1;
}

.rst__loadingHandle {
  cursor: default;
  background: #d9d9d9;
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.rst__loadingCircle {
  width: 80%;
  height: 80%;
  margin: 10%;
  position: relative;
}

.rst__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.rst__rtl.rst__loadingCirclePoint {
  right: 0;
  left: initial;
}

.rst__loadingCirclePoint::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  animation: pointFade 800ms infinite ease-in-out both;
}
.rst__loadingCirclePoint:nth-of-type(1) {
  transform: rotate(0deg);
}
.rst__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}
.rst__loadingCirclePoint:nth-of-type(1)::before,
.rst__loadingCirclePoint:nth-of-type(7)::before {
  animation-delay: -800ms;
}
.rst__loadingCirclePoint:nth-of-type(2) {
  transform: rotate(30deg);
}
.rst__loadingCirclePoint:nth-of-type(8) {
  transform: rotate(210deg);
}
.rst__loadingCirclePoint:nth-of-type(2)::before,
.rst__loadingCirclePoint:nth-of-type(8)::before {
  animation-delay: -666ms;
}
.rst__loadingCirclePoint:nth-of-type(3) {
  transform: rotate(60deg);
}
.rst__loadingCirclePoint:nth-of-type(9) {
  transform: rotate(240deg);
}
.rst__loadingCirclePoint:nth-of-type(3)::before,
.rst__loadingCirclePoint:nth-of-type(9)::before {
  animation-delay: -533ms;
}
.rst__loadingCirclePoint:nth-of-type(4) {
  transform: rotate(90deg);
}
.rst__loadingCirclePoint:nth-of-type(10) {
  transform: rotate(270deg);
}
.rst__loadingCirclePoint:nth-of-type(4)::before,
.rst__loadingCirclePoint:nth-of-type(10)::before {
  animation-delay: -400ms;
}
.rst__loadingCirclePoint:nth-of-type(5) {
  transform: rotate(120deg);
}
.rst__loadingCirclePoint:nth-of-type(11) {
  transform: rotate(300deg);
}
.rst__loadingCirclePoint:nth-of-type(5)::before,
.rst__loadingCirclePoint:nth-of-type(11)::before {
  animation-delay: -266ms;
}
.rst__loadingCirclePoint:nth-of-type(6) {
  transform: rotate(150deg);
}
.rst__loadingCirclePoint:nth-of-type(12) {
  transform: rotate(330deg);
}
.rst__loadingCirclePoint:nth-of-type(6)::before,
.rst__loadingCirclePoint:nth-of-type(12)::before {
  animation-delay: -133ms;
}
.rst__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}
.rst__loadingCirclePoint:nth-of-type(13) {
  transform: rotate(360deg);
}
.rst__loadingCirclePoint:nth-of-type(7)::before,
.rst__loadingCirclePoint:nth-of-type(13)::before {
  animation-delay: 0ms;
}

.rst__rowTitle {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;
}

.rstcustom__rowTitle {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;
}

.rst__rowTitleWithSubtitle {
  font-size: 85%;
  display: block;
  height: 0.8rem;
}

.rst__rowSubtitle {
  font-size: 70%;
  line-height: 1;
}

.rst__collapseButton,
.rst__expandButton {
  -webkit-appearance: none;
          appearance: none;
  border: none;
  position: absolute;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #000;
  width: 16px;
  height: 16px;
  padding: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.rst__rtl.rst__collapseButton,
.rst__rtl.rst__expandButton {
  transform: translate(50%, -50%);
}
.rst__collapseButton:focus,
.rst__expandButton:focus {
  outline: none;
  box-shadow: 0 0 0 1px #000, 0 0 1px 3px #83bef9;
}
.rst__collapseButton:hover:not(:active),
.rst__expandButton:hover:not(:active) {
  background-size: 24px;
  height: 20px;
  width: 20px;
}

.rst__collapseButton {
  background: #fff
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==')
    no-repeat center;
}

.rst__expandButton {
  background: #fff
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48cGF0aCBkPSJNOSA0LjV2OSIvPjwvZz4KPC9zdmc+')
    no-repeat center;
}

/**
 * Line for under a node with children
 */
.rst__lineChildren {
  height: 100%;
  display: inline-block;
  position: absolute;
}
.rst__lineChildren::after {
  content: '';
  position: absolute;
  background-color: black;
  width: 1px;
  left: 50%;
  bottom: 0;
  height: 10px;
}

.rst__rtl.rst__lineChildren::after {
  right: 50%;
  left: initial;
}

.rst__placeholder {
  position: relative;
  height: 68px;
  max-width: 300px;
  padding: 10px;
}
.rst__placeholder,
.rst__placeholder > * {
  box-sizing: border-box;
}
.rst__placeholder::before {
  border: 3px dashed #d9d9d9;
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  z-index: -1;
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.rst__placeholderLandingPad,
.rst__placeholderCancelPad {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.rst__placeholderLandingPad *,
.rst__placeholderCancelPad * {
  opacity: 0 !important;
}
.rst__placeholderLandingPad::before,
.rst__placeholderCancelPad::before {
  background-color: lightblue;
  border-color: white;
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.rst__placeholderCancelPad::before {
  background-color: #e6a8ad;
}

/* Collection default theme */

.ReactVirtualized__Collection {
}

.ReactVirtualized__Collection__innerScrollContainer {
}

/* Grid default theme */

.ReactVirtualized__Grid {
}

.ReactVirtualized__Grid__innerScrollContainer {
}

/* Table default theme */

.ReactVirtualized__Table {
}

.ReactVirtualized__Table__Grid {
}

.ReactVirtualized__Table__headerRow {
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ReactVirtualized__Table__row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 10px;
  min-width: 0px;
}
.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 10px;
}
.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: flex;
  align-items: center;
}
.ReactVirtualized__Table__sortableHeaderIcon {
  flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor;
}

/* List default theme */

.ReactVirtualized__List {
}

.selected-node{
  background-color: #999999!important;
}


.rstcustom__rowContents {
  position: relative;
  height: 100%;
  padding: 0 5px 0 30px;
  min-width: 230px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: unset!important;
  box-shadow: 0 0px 0px rgb(0 0 0), 0 0px 0px rgb(0 0 0)!important;
  transition: all 0.3s cubic-bezier(0, 0, 0, 0)!important;
}
.rstcustom__node {
  width: 50%;
  white-space: nowrap;
  position: relative;
  text-align: left;
  min-width: 100%;
  overflow: hidden;
  border-bottom: 1px solid rgb(243, 242, 241)!important;;
  background: rgb(255, 255, 255);
  color: rgb(96, 94, 92);
  height: 40px!important;
  cursor: pointer !important;
}
.rstcustom__node:hover {
  width: 50%;
  white-space: nowrap;
  position: relative;
  text-align: left;
  min-width: 100%;
  overflow: hidden;
  border-bottom: 1px solid rgb(243, 242, 241)!important;;
  background: rgb(243 242 241)!important;
  color: rgb(96, 94, 92)!important;
}


.chart .rstcustom__node {
  width: 50%;
  white-space: nowrap;
  position: relative;
  text-align: left;
  min-width: 100%;
  overflow: hidden;
  border-bottom: 1px solid rgb(243, 242, 241)!important;;
  background: none;
  color: rgb(96, 94, 92);
  height: 36px!important;
  cursor: pointer !important;
}

.chart .rstcustom__node .rstcustom__rowWrapper .rstcustom__lineBlock {
  /*width: 15px!important;*/
}

.chart .rstcustom__node .rstcustom__rowWrapper .rstcustom__collapseButton {
  /*left: 4.5px!important;*/
}

.chart .rstcustom__node:hover {
  width: 50%;
  white-space: nowrap;
  position: relative;
  text-align: left;
  min-width: 100%;
  overflow: hidden;
  border-bottom: 1px solid rgb(243, 242, 241)!important;;
  background: none;
  color: rgb(96, 94, 92)!important;
}

.rstcustom__rowLandingPad {
  width: 50%;
  white-space: nowrap;
  position: relative;
  text-align: left;
  min-width: 100%;
  overflow: hidden;
  border-bottom: 1px solid rgb(243, 242, 241)!important;;
  background: lightblue !important;
  color: rgb(96, 94, 92)!important;
 
}

.rstcustom__rowCancelPad {
  display: flex;
  align-items: center;
  background: #D98880 !important;
}
/* .previous:has(.rstcustom__node) {
  display: flex;
  align-items: center;
  background: red !important;
} */


.treeHeader{
  width: 100%;
  border-bottom: 1px solid rgb(243, 242, 241)!important;
  background: rgb(255, 255, 255);
  color: rgb(96, 94, 92);
  height: 40px!important;
  font-weight: 600;
  font-size: 14px;
  margin-left: 74px!important;
}

.rstcustom__collapseButton, .rstcustom__expandButton {
  height: 20px!important;
}


.orgSetting .rstcustom__expandButton::before {
  content: '' !important;
}

.orgSetting .rstcustom__collapseButton::before {
  content: '' !important;
}
.taxonomyDot{
  color:black;
}

.chart .rstcustom__collapseButton::before {
  content: '' !important;
}

.chart. rstcustom__collapseButton {
  left: 4.5px!important;
}

.chart .ms-TextField-field {
  cursor: pointer
}

.chart .field-575 {
  padding: 0px 2px !important;
}

.rstcustom__node:has(.emptyRow) + .rstcustom__node .rstcustom__collapseButton  {
  display: none !important;
 
}
.rstcustom__node:has(.emptyRow) > .rstcustom__collapseButton  {
  display: none !important;
 
}
/* 
.emptyRow > .rstcustom__node .rstcustom__collapseButton  {
  display: none !important;
 
} */

.event-body-container {
  text-decoration: none;
  position: relative;
}
.event-body-container .tooltip-text {
  background-color: #fff;
  z-index: 10;
  /* Fade effect */
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 2px;
  font-size: 11px;
  font-weight: 400;
  border: #e0e0e0 solid 1px;
  padding: 2px 6px;
}
.event-body-container #tooltip-id {
  display: none;
}
.event-body-container:hover #tooltip-id {
  display: block;
  position: fixed;
  overflow: hidden;
  opacity: 1;
}

.task-list-header_ganttTable__1jXi3 {
    display: table;
    border-bottom: #e6e4e4 1px solid;
    border-top: #e6e4e4 1px solid;
    border-left: #e6e4e4 1px solid;
}

.task-list-header_ganttTable_Header__3J2fy {
    display: table-row;
    list-style: none;
}

.task-list-header_ganttTable_HeaderSeparator__3_g71 {
    border-right: 1px solid rgb(196, 196, 196);
    opacity: 1;
    margin-left: -2px;
}

.task-list-header_ganttTable_HeaderItem__2TjFy {
    display: table-cell;
    vertical-align: -webkit-baseline-middle;
    vertical-align: middle;
}

.tooltip_tooltipDefaultContainer__3kGju {
    background: #fff;
    padding: 12px;
    margin-top: 30px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.tooltip_tooltipDefaultContainerParagraph__1x3bG {
    font-size: 12px;
    margin-bottom: 6px;
    color: #666;
}

.tooltip_tooltipDetailsContainer__1yeJt {
    position: absolute;
    display: flex;
    flex-shrink: 0;
    pointer-events: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

.tooltip_tooltipDetailsContainerHidden__3l9rn {
    visibility: hidden;
    position: absolute;
    display: flex;
    pointer-events: none;
}

.vertical-scroll_scroll__2n2P3 {
    overflow: hidden auto;
    width: 17px;
    flex-shrink: 0;
}

.grid_gridRow__1YZwI {
    fill: #fff;
}

.grid_gridRow__1YZwI:nth-child(even) {
    fill: #f5f5f5;
}

.grid_gridRowLine__23Ddd {
    stroke: #ebeff2;
}

.grid_gridTick__M0HVS {
    stroke: #e6e4e4;
}


.calendar_calendarBottomText__10OGO {
    text-anchor: middle;
    fill: #333;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
}

.calendar_calendarBottomTextWeek__3kzpK {
    width:'100%';
    fill: #333;
}

.calendar_calendarTopTick__MCMuZ {
    stroke: #e6e4e4;
}

.calendar_calendarTopText__1OcYG {
    text-anchor: middle;
    fill: #555;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
}

.calendar_calendarHeader__12nhb {
    fill: #ffffff;
    stroke: #e0e0e0;
    stroke-width: 1.4;
}

.bar_barWrapper__3y2_x {
    cursor: pointer;
    outline: none;
}

.bar_barWrapper__3y2_x:hover .bar_barHandle__3G5_x {
    visibility: visible;
    opacity: 1;
}

.bar_barHandle__3G5_x {
    fill: #ddd;
    cursor: ew-resize;
    opacity: 0;
    visibility: hidden;
}

.bar_barBackground__1zmTS {
    -webkit-user-select: none;
            user-select: none;
    stroke-width: 0;
}

.bar_contextMenu__9SAi2 {
    position: fixed;
    background: white;
    box-shadow: 0px 2px 10px #999999;
}

.milestone_milestoneWrapper__RAxN0 {
    cursor: pointer;
    outline: none;
}

.milestone_milestoneBackground__1kVp_ {
    -webkit-user-select: none;
            user-select: none;
}

.project_projectWrapper__2F-IB {
    cursor: pointer;
    outline: none;
}

.project_projectBackground__1BHH5 {
    -webkit-user-select: none;
            user-select: none;
    opacity: 0.6;
}

.project_projectTop__e_xWi {
    -webkit-user-select: none;
            user-select: none;
}

.project_productWrapper__V-GOu {
    cursor: pointer;
    outline: none;
    bottom: 0;
    margin-bottom: -10
}

.task-list_barLabel__3DIN3 {
  fill: #fff;
  text-anchor: middle;
  font-weight: lighter;
  dominant-baseline: central;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.task-list_barLabelOutside__3SUjy {
  fill: #555;
  text-anchor: start;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.task-list_productLabel__w_ujY {
  fill: #000;
  text-anchor: middle;
  font-weight: lighter;
  dominant-baseline: central;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}


.bar_barWrapper__SUlN6 {
    cursor: pointer;
    outline: none;
}

.bar_barWrapper__SUlN6:hover .bar_barHandle__3ZY1_ {
    visibility: visible;
    opacity: 1;
}

.bar_barHandle__3ZY1_ {
    fill: #ddd;
    cursor: ew-resize;
    opacity: 0;
    visibility: hidden;
}

.bar_barBackground__1uSfG {
    -webkit-user-select: none;
            user-select: none;
    stroke-width: 0;
}

.bar_contextMenu__1nbDY {
    position: fixed;
    background: white;
    box-shadow: 0px 2px 10px #999999;
}

.bar_projectTop__2qRDT {
    -webkit-user-select: none;
            user-select: none;
}


.gantt_ganttVerticalContainer__3jys7 {
    overflow: hidden;
    font-size: 0;
    margin: 0;
    padding: 0;
}

.gantt_horizontalContainer__3olJ8 {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.gantt_wrapper__A5KEa {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    outline: none;
    position: relative;
}



.horizontal-scroll_scroll__1n6j4 {
    overflow: auto;
    max-width: 100%;
}

.btn-wrapper {
    display: block;
    max-width: 200px;
    padding: 4px;
}
:root {
    --pmolWork: #92E950;
    --pmolTravel: #F35325;
    --pmolLoad: #04A6F0;
}

.day {
    height: 10px !important;
    background-color: #f2f2f2;
}

.kanbanTable tr {
    height: 10px;
}

.kanbanTable {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
    border-collapse: collapse;
    width: 100%;
}

.kanbanTable td, .kanbanTable th {
    border: 1px solid #ddd;
    padding: 8px;
}

/*.kanbanTable tr:nth-child(even){background-color: #f2f2f2;}*/

.kanbanTable tr:hover {
    background-color: #ddd;
}

.kanbanTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: white;
    color: white;
}

.pmolbox {
    height: 28px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 5px;
    background-color: #36c2f6;
    padding: 8px;
    display: initial;
}

.dragBox {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
    padding: 2px;
    margin: 2px;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.noneDragBox {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
    padding: 5px;
    margin: 5px;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.dragBox:hover {
    background-color: rgb(245, 250, 250);
    transition: background-color 275ms ease 0s;
    cursor: move;
    border: 3px rgba(18, 18, 18, 0.68) !important;
    box-shadow: 5px 8px 15px #888888;
}

.noneDragBox:hover {
    background-color: rgb(245, 250, 250);
    transition: background-color 275ms ease 0s;
    cursor: default;
    border: 3px rgba(18, 18, 18, 0.68) !important;
    box-shadow: 5px 8px 15px #888888;
}

.dropBoxActive {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
    background-color: rgb(235, 242, 250);
    transition: background-color 275ms ease 0s;
    cursor: pointer;
    border: 3px dashed #1c87c9 !important;
    color: #000000;
    font-weight: bolder;
    align-items: center;
    justify-content: center;
    display: flex;
}

.dropBoxTdActive {
    font-family:"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important ;
    background-color: rgb(235, 242, 250);
    transition: background-color 275ms ease 0s;
    cursor: pointer;
    border: 3px dashed #1c87c9!important;
    color:#000000;
    font-weight: bolder;
    align-items:center;
    justify-content:center;
    position: relative;
}
#dropOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: .5s ease;
    background-color: rgb(235, 242, 250);
    align-items:center;
    justify-content:center;
    text-align: center;
}

#dropOverlay #text{
    padding-top: 10%;
}
.th-automation #dropOverlay #text{
    padding-top: 2% !important;
}
.th-automation .thaEventWrapper.concrete-mixer.notLanding #dropOverlay #text{
    display: none !important;
}

.rbc-time-header-gutter #dropOverlay #text{
    display: none !important;
}

.dragBox i {
    vertical-align: middle;
}

.dropBox {
    padding: 2px;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
}

/*big calender style*/
@charset "UTF-8";
.rbc-btn {
    color: inherit;
    font: inherit;
    margin: 0;
}

button.rbc-btn {
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled].rbc-btn {
    cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.rbc-calendar {
    /*font-family:"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important ;*/
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
    box-sizing: inherit;
}

.rbc-abs-full, .rbc-row-bg {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.rbc-row-bg {
    border-right: 1px solid #DDD !important;
}

.rbc-ellipsis, .rbc-event-label, .rbc-row-segment .rbc-event-content, .rbc-show-more {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rbc-rtl {
    direction: rtl;
}

.rbc-off-range {
    color: #999999;
}

.rbc-off-range-bg {
    background: #e6e6e6;
}

.rbc-header {
    width: 250px;
    overflow: hidden;
    flex: 1 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 3px;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 90%;
    min-height: 0;
    border-bottom: 1px solid #FFF;
}

.rbc-header + .rbc-header {
    border-left: 1px solid #DDD;
}

.rbc-customeHeader {
    position: relative;
}

.rbc-rtl .rbc-header + .rbc-header {
    border-left-width: 0;
    border-right: 1px solid #DDD;
}

.rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
    color: inherit;
    text-decoration: none;
}

.rbc-row-content {
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    z-index: 4;
}

.rbc-row-content-scrollable {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container {
    height: 100%;
    overflow-y: scroll;
    /* Hide scrollbar for Chrome, Safari and Opera */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
    display: none;
}

.rbc-today {
    background-color: #ffffff;
}

.rbc-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
}

.rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0 10px;
    text-align: center;
}

.rbc-toolbar button {
    color: #373a3c;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid #ccc;
    padding: .375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap;
}

 .rbc-toolbar .ms-style button{
    border: none!important;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    background-color: #e6e6e6;
    border-color: #adadad;
}

.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
    color: #373a3c;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
}

.rbc-toolbar button:focus {
    color: #373a3c;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.rbc-toolbar button:hover {
    color: #373a3c;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.rbc-btn-group {
    display: inline-block;
    white-space: nowrap;
}

.rbc-btn-group > button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-btn-group > button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-btn-group > button:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.rbc-btn-group button + button {
    margin-left: -1px;
}

.rbc-rtl .rbc-btn-group button + button {
    margin-left: 0;
    margin-right: -1px;
}

.rbc-btn-group + .rbc-btn-group,
.rbc-btn-group + button {
    margin-left: 10px;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 2px 5px;
    background-color: #3174ad;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left;
}

.rbc-slot-selecting .rbc-event, .rbc-slot-selecting .rbc-day-slot .rbc-background-event, .rbc-day-slot .rbc-slot-selecting .rbc-background-event {
    cursor: inherit;
    pointer-events: none;
}

.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
    background-color: #265985;
}

.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
    outline: 5px auto #3b99fc;
}

.rbc-event-label {
    font-size: 80%;
}

.rbc-event-overlaps {
    box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.rbc-event-continues-later {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-row {
    display: flex;
    flex-direction: row;
}

.rbc-row-segment {
    padding: 0 1px 1px 1px;
}

.rbc-selected-cell {
    background-color: rgba(0, 0, 0, 0.1);
}

.rbc-show-more {
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 4;
    font-weight: bold;
    font-size: 85%;
    height: auto;
    line-height: normal;
}

.rbc-month-view {
    position: relative;
    border: 1px solid #DDD;
    display: flex;
    flex-direction: column;
    flex: 1 0;
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
    height: 100%;
}

.rbc-month-header {
    display: flex;
    flex-direction: row;
}

.rbc-month-row {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1 0;
    flex-basis: 0px;
    overflow: hidden;
    height: 100%;
}

.rbc-month-row + .rbc-month-row {
    border-top: 1px solid #DDD;
}

.rbc-date-cell {
    flex: 1 1;
    min-width: 0;
    padding-right: 5px;
    text-align: right;
}

.rbc-date-cell.rbc-now {
    font-weight: bold;
}

.rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
    color: inherit;
    text-decoration: none;
}

.rbc-row-bg {
    display: flex;
    flex-direction: row;
    flex: 1 0;
    overflow: hidden;
}

.rbc-day-bg {
    flex: 1 0;
}

.rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #DDD;
}

.rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid #DDD;
}

.rbc-overlay {
    position: absolute;
    z-index: 5;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    padding: 10px;
}

.rbc-overlay > * + * {
    margin-top: 1px;
}

.rbc-overlay-header {
    border-bottom: 1px solid #e5e5e5;
    margin: -10px -10px 5px -10px;
    padding: 2px 10px;
}

.rbc-agenda-view {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    overflow: auto;
}

.rbc-agenda-view table.rbc-agenda-table {
    width: 100%;
    border: 1px solid #DDD;
    border-spacing: 0;
    border-collapse: collapse;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
    padding: 5px 10px;
    height: 40px !important;
    vertical-align: top;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
    padding-left: 15px;
    padding-right: 15px;
    text-transform: lowercase;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left: 1px solid #DDD;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left-width: 0;
    border-right: 1px solid #DDD;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
    border-top: 1px solid #DDD;
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    padding: 3px 5px;
    text-align: center;
    border-bottom: 1px solid #DDD;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    text-align: right;
}

.rbc-agenda-time-cell {
    text-transform: lowercase;
}

.rbc-agenda-time-cell .rbc-continues-after:after {
    content: ' »';
}

.rbc-agenda-time-cell .rbc-continues-prior:before {
    content: '« ';
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
    white-space: nowrap;
}

.rbc-agenda-event-cell {
    width: 100%;
}

.rbc-time-column {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.rbc-time-column .rbc-timeslot-group {
    flex: 1 1;
}

.rbc-timeslot-group {
    border-bottom: 1px solid #DDD;
    min-height: 40px;
    display: flex;
    flex-flow: column nowrap;
}

.rbc-time-gutter,
.rbc-header-gutter {
    flex: none;
}

.rbc-label {
    padding: 0 5px;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif!important;
}

.rbc-day-slot {
    position: relative;
}

.rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    margin-right: 10px;
    top: 0;
}

.rbc-day-slot .rbc-events-container.rbc-rtl {
    left: 10px;
    right: 0;
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
    border: 1px solid #265985;
    display: flex;
    max-height: 100%;
    min-height: 20px;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute;
}

.rbc-day-slot .rbc-background-event {
    opacity: 0.75;
}

.rbc-day-slot .rbc-event-label {
    flex: none;
    padding-right: 5px;
    width: auto;
}

.rbc-day-slot .rbc-event-content {
    width: 100%;
    flex: 1 1;
    word-wrap: break-word;
    line-height: 1;
    height: 100%;
    min-height: 1em;
}

.rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #f7f7f7;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background-color: white;
    border-right: 1px solid #DDD;
    z-index: 10;
    margin-right: -1px;
}

.rbc-time-view-resources .rbc-time-header {
    overflow: hidden;
}

.rbc-time-view-resources .rbc-time-header-content {
    min-width: auto;
    flex: 1 0;
    flex-basis: 0px;
}

.rbc-time-view-resources .rbc-time-header-cell-single-day {
    display: none;
}

.rbc-time-view-resources .rbc-day-slot {
    min-width: 140px;
}

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
    width: 140px;
    flex: 1 1;
    flex-basis: 0px;
}

.rbc-time-header-content + .rbc-time-header-content {
    margin-left: -1px;
}

.rbc-time-slot {
    flex: 1 0;
}

.rbc-time-slot.rbc-now {
    font-weight: bold;
}

.rbc-day-header {
    text-align: center;
}

.rbc-slot-selection {
    z-index: 10;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 75%;
    width: 100%;
    padding: 3px;
}

.rbc-slot-selecting {
    cursor: move;
}

.rbc-time-view {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    width: 100%;
    border: 1px solid #DDD;
    min-height: 0;
}

.rbc-time-view .rbc-time-gutter {
    white-space: nowrap;
}

.rbc-time-view .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    position: relative;
}

.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
    border-left: 1px solid #DDD;
}

.rbc-time-view .rbc-allday-events {
    position: relative;
    z-index: 4;
}

.rbc-time-view .rbc-row {
    box-sizing: border-box;
    min-height: 20px;
}

.rbc-time-header {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
}

.rbc-time-header.rbc-overflowing {
    border-right: 1px solid #DDD;
}

.rbc-rtl .rbc-time-header.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid #DDD;
}

.rbc-time-header > .rbc-row:first-child {
    border-bottom: 1px solid #DDD;
}

.rbc-time-header > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #DDD;
}

.rbc-time-header-cell-single-day {
    display: none;
}

.rbc-time-header-content {
    flex: 1 1;
    display: flex;
    min-width: 0;
    flex-direction: column;
    border-left: 1px solid #DDD;
}

.rbc-rtl .rbc-time-header-content {
    border-left-width: 0;
    border-right: 1px solid #DDD;
}

.rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #FFF;
    flex-shrink: 0;
}

.rbc-time-content {
    display: flex;
    flex: 1 0;
    align-items: flex-start;
    width: 100%;
    border-top: 2px solid #DDD;
    overflow-y: auto;
    position: relative;
}

.rbc-time-content > .rbc-time-gutter {
    flex: none;
}

.rbc-time-content > * + * > * {
    border-left: 1px solid #DDD;
}

.rbc-rtl .rbc-time-content > * + * > * {
    border-left-width: 0;
    border-right: 1px solid #DDD;
}

.rbc-time-content > .rbc-day-slot {
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
}

.rbc-current-time-indicator {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #74ad31;
    pointer-events: none;
}


.rbc-time-header-cell {
    border-top: 1px solid #DDD !important;
}

.dropBoxSubItem {
    overflow-wrap: break-word;
    width: 100%;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 3px;
    font-size: 11px;
}

.gridDragBox{

}

.customEventWrapper.travel .rbc-event{
    /*background-color: #EB5757  !important;*/
    background-color: var(--pmolTravel)  !important;
}
.customEventWrapper.travel .rbc-event .rbc-event-label {
    color: white!important;
}

.customEventWrapper.work .rbc-event{
    /*background-color: #07AA6B  !important;*/
    background-color: var(--pmolWork)  !important;
}

.customEventWrapper.load .rbc-event{
    /*background-color: #07A6B2  !important;*/
    background-color: var(--pmolLoad)  !important;
}
.customEventWrapper.load .rbc-event .rbc-event-label {
    color: white!important;
}

.customEventWrapper .rbc-event{
    display: block !important;
}

.customEventWrapper .rbc-event-label, .dropBox{
    color: black !important;
}

.rbc-allday-cell{
    display: none;
}

.project-day_planing .rbc-time-header.rbc-overflowing{
    height: 70px;
}

.project-day_planing .rbc-event-label {
    font-size: 72%;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif!important;
}
.pmol-title-pdp{
    font-size: 13px;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif!important;
    z-index: 1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.three-dots {
    cursor: pointer;
    font-size: 12px;
    padding: 0 5px;
}

.pmol-time{
    font-size: 12px;
    font-weight: 600;
    padding-left: 10px;
    min-width: 150px;
    margin-right: 5px;
}
.pmol-title{
    padding-left: 10px;
}
.customEventWrapper.travel .three-dots{
    color: var(--pmolTravel)  !important;
}
.customEventWrapper.work .three-dots{
    color: var(--pmolWork)  !important;
}

.customEventWrapper.load .three-dots{
    color: var(--pmolLoad)  !important;
}
.customEventWrapper.travel .pmol-time{
    color: var(--pmolTravel)  !important;
}
.customEventWrapper.work .pmol-time{
    color: var(--pmolWork)  !important;
}

.customEventWrapper.load .pmol-time{
    color: var(--pmolLoad)  !important;
}




.customEventWrapper.travel .tool-count {
    background-color: var(--pmolTravel)  !important;
}
.customEventWrapper.work .tool-count {
    background-color: var(--pmolWork)  !important;
}
.customEventWrapper.load .tool-count {
    background-color: var(--pmolLoad)  !important;
}
.customEventWrapper.travel .button__badge,.tool-count {
    background-color: var(--pmolTravel)  !important;
}
.customEventWrapper.work .button__badge,.tool-count {
    background-color: var(--pmolWork)  !important;
}

.customEventWrapper.load .button__badge,.tool-count {
    background-color: var(--pmolLoad)  !important;
}

.navbar-pmol {
    font-size: 22px;
    padding: 0px 0px;
}

/* Define what each icon button should look like */
.button {
    display: inline-block; /* Inline elements with width and height. TL;DR they make the icon buttons stack from left-to-right instead of top-to-bottom */
    position: relative; /* All 'absolute'ly positioned elements are relative to this one */
    padding: 2px 5px; /* Add some padding so it looks nice */
    color: black;
}

/* Make the badge float in the top right corner of the button */
.button__badge {
    border-radius: 5px;
    color: white;

    padding: 1px 3px;
    font-size: 10px;

    position: absolute; /* Position the badge within the relatively positioned button */
    top: 0;
    right: 0;
}
.tool-count{
    font-size: 9px;
    margin-left: 10px;
    border-radius: 10px;
    width: 34px;
    text-align: center;
    padding-top: 3px;
    color: white;
    height: 15px;
}

.vertical-line{
    background-color: #e3e0de;
    width: 1px;
    height: 30px;
    margin-left: 3px;
    margin-right: 3px;
}

.rbc-time-view-resources .rbc-time-header-content{
    min-width: 330px!important;
}
.rbc-time-content > .rbc-day-slot{
    min-width: 329px!important;
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event{
    border: none;
}

.pie-chart{
    border-radius: 50%;
}
.pie-chart:hover{
    background-color: rgb(245, 250, 250);
    transition: background-color 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 10s;
    /*border: 3px rgba(18, 18, 18, 0.68) !important;*/
    box-shadow: 2px 2px 6px #acacac;
}

.plan-board-calender-with-borders .rbc-header {
    /*border: 1px solid black !important;*/
    padding: 0px !important;
}

.plan-board-calender-with-borders .rbc-time-view-resources .rbc-time-gutter, .rbc-time-view-resources .rbc-time-header-gutter {
    /*border: 1px solid black !important;*/
}

.plan-board-calender-with-borders .rbc-time-content > * + * > * {
    /*border-left: 1px solid black !important;*/
}

.plan-board-calender-with-borders .rbc-time-header-gutter {
    /*border-left: 1px solid black !important;*/
}

.plan-board-calender-with-borders .rbc-timeslot-group {
    /*border-left: 1px solid black !important;*/
}

.plan-board-calender-with-borders .weekDate-364 {
    /*border-left: 1px solid black !important;*/
}

.plan-board-calender-with-borders .dropBox {
    /*border-left: 1px solid black !important;*/
}

.plan-board-calender-with-borders .pmol-dropBox {
    padding: 2px;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
}

.plan-board-calender-with-borders.show-all-in-screen .rbc-time-content > .rbc-day-slot {
    min-width: 10px!important;
}
.plan-board-calender-with-borders.show-all-in-screen .rbc-time-view-resources .rbc-time-header-content{
    min-width: 10px!important;
}

.th-automation{
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif!important;
}

.th-automation .rbc-time-content > .rbc-day-slot {
    min-width: 10px!important;
}
.th-automation .rbc-time-view-resources .rbc-time-header-content{
    min-width: 10px!important;
}
.th-automation .rbc-time-header-content{
    border: none!important;
}
.th-automation .rbc-time-header{    /* //TODO: uncomment for sticky header */
    position: -webkit-sticky;
    position: sticky;
    top: 90px;
    z-index: 11;
    background: white;
}
.th-automation .rbc-row-resource .rbc-header{
    padding: 0!important;
}
/*.project-day_planing .rbc-event-label{*/
/*    visibility: hidden;*/
/*    font-size: 3px;*/
/*}*/

.th-automation .hide-time .rbc-time-view-resources .rbc-time-gutter, .rbc-time-view-resources .rbc-time-header-gutter  {
    display: none;
}
.th-automation .hide-time .rbc-label.rbc-time-header-gutter  {
    display: none;
}
.th-automation .isFirst .rbc-time-view-resources .rbc-time-gutter, .rbc-time-view-resources .rbc-time-header-gutter  {
    display: inherit;
}
.th-automation .custom-toolbar{
    min-height: 47px;
    text-align: center;
    border: 1px solid #ddd;
    font-weight: bold;
    font-size: 90%;
    color: #e5e5e5;
    position: -webkit-sticky;
    position: sticky;  /* //TODO: uncomment for sticky header */
    top: 0;
    z-index: 11;
}
.th-automation .custom-toolbar .pbsDynamicAttributes{
    min-height: 47px;
    text-align: center;
    font-weight: normal;
    font-size: 90%;
}
.th-automation .rbc-event-label{
   display: none;
}
.th-automation .rbc-event{
   left: 10% !important;
    max-width: 85%!important;
    min-width: 85%!important;
}
.th-automation .rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event{
   /*border: none!important;*/
    border-radius: 0!important;
}
.th-automation  .virtual_product .rbc-event{
    background-color: #F7D166!important;
    max-width: 85%!important;
    border: #2D3A4A 1px solid!important;

}
.th-automation  .pump_product .rbc-event{
    background-color: #70ad47!important;
    max-width: 56px!important;
    border: #2D3A4A 1px solid!important;
    min-width: 56px!important;

}
.th-automation  .pump-car.pmol_travel1 .rbc-event{
    border-style: solid solid none solid;
    border-color: #000000;
    border-width: 2px;
}
.th-automation  .pmol_loading .rbc-event{
    background-color: var(--pmolLoad)!important;
    max-width: 85%!important;
    border-style: solid solid none solid;
    border-color: #000000;
    border-width: 2px;
}

.th-automation  .pmol_travel1 .rbc-event{
    background-color: var(--pmolTravel)!important;
    max-width: 85%!important;
    border-style: none solid none solid;
    border-color: #000000;
    border-width: 2px;
}
.th-automation  .pmol_unloading .rbc-event{
    background-color: #70ad47!important;
    max-width: 85%!important;
    border-style: none solid none solid;
    border-color: #000000;
    border-width: 2px;
}
.th-automation  .pmol_travel2 .rbc-event{
    background-color: var(--pmolTravel)!important;
    max-width: 85%!important;
    border-style: none solid solid solid;
    border-color: #000000;
    border-width: 2px;
}
.th-automation  .pmol_loading .rbc-event i{
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
}
.th-automation  .pmol_travel2 .rbc-event i{
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
}

.th-automation .concrete-mixer .rbc-event{
   background-color: #70ad47!important;
    max-width: 56px!important;
    border: none!important;
    min-width: 56px!important;
}
.th-automation .concrete-mixer.notLanding .rbc-event{
   background-color: #ed7d31!important;
}
.th-automation .concrete-mixer.meter .rbc-event{
   background-color: #ffffff !important;
   border: #2D3A4A 1px solid;
}
.th-automation .concrete-mixer.meter .rbc-event, .rbc-day-slot .rbc-background-event{
    border: #2D3A4A 1px solid!important;
    border-radius: 0!important;
}
.th-automation .concrete-mixer .rbc-event, .rbc-day-slot .rbc-background-event{
    border: #2D3A4A 1px solid!important;
    border-radius: 0!important;
}
.th-automation-bottom .rbc-event-label{
    display: none;
}

.th-automation-bottom .rbc-event{
    /*border: 1px solid #000000!important;*/
    border-radius: 0!important;
    background-color: transparent;
    border: none !important;
    cursor: default;
}

.th-automation-bottom .rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event{
    /*border: #000000 1px solid!important;*/
}

.delete-button {
    color: #000000;
    fontSize: 14px;
    cursor: pointer;
    margin: 4px;
}

.delete-button:hover {
    background-color: rgb(245, 250, 250);
    transition: background-color 275ms ease 0s;
    border: 3px rgba(18, 18, 18, 0.68) !important;
    box-shadow: 5px 8px 11px #888888;
}

@media only screen and (min-width: 2000px) {
    .th-automation .custom-calender{
      height: 100% !important;
    }
    .th-automation .concrete-mixer .rbc-event{
        max-width: 70px!important;
        min-width: 70px!important;
    }
}
@media only screen and (min-width: 3000px) {
    .th-automation .custom-calender{
      height: 80% !important;
    }
    .th-automation .concrete-mixer .rbc-event{
        max-width: 80px!important;
        min-width: 80px!important;
    }
}
.th-automation .custom-calender.set-height{
    height: 100% !important;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor .rbc-addons-dnd-resize-ns-icon {
    width: 100% !important;
    /*border-top: 4px solid;*/
    /*margin: -1px auto;*/
}

.rbc-time-header-gutter{
    width: 50.9688px !important;
    min-width: 50.9688px !important;
    max-width: 50.9688px !important;
}

.rbc-time-gutter.rbc-time-column{
    width: 50.9688px !important;
    min-width: 50.9688px !important;
    max-width: 50.9688px !important;
}

@charset "UTF-8";
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  margin-left: -4px;
  position: absolute;
  width: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  top: 0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  border-bottom: none;
  border-top-color: #fff;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  bottom: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}
.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 10px;
}
.react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle, .react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}
.react-datepicker-popper[data-placement^=top] {
  padding-bottom: 10px;
}
.react-datepicker-popper[data-placement^=right] {
  padding-left: 8px;
}
.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}
.react-datepicker-popper[data-placement^=left] {
  padding-right: 8px;
}
.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 15px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__navigation--previous {
  left: 2px;
}
.react-datepicker__navigation--next {
  right: 2px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
}
.react-datepicker__navigation:hover *::before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.react-datepicker__navigation-icon--next {
  left: -2px;
}
.react-datepicker__navigation-icon--next::before {
  transform: rotate(45deg);
  left: -7px;
}
.react-datepicker__navigation-icon--previous {
  right: -2px;
}
.react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: -7px;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}
.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}
.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}
.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2579ba;
  color: #fff;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  transform: rotate(135deg);
  right: -16px;
  top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}
:root {
    --horizontal-resizer: 328px;
}

.wrapper {
    height: calc(100vh - 14vh) !important;
    position: relative;
}

.selected {
    font-weight: bold;
    color: #854114 !important;
}

.hr {
    margin-bottom: 15px;
    border-top: 1px solid #d2d2d2;
}

.fullWidth {
    width: 100%;
}

.halfWidth {
    width: 49%;
}

.taxonomy-wrapper {
    height: calc(var(--horizontal-resizer, 1820px) - 354px);
    margin-bottom: 10px;
    width: -webkit-max-content;
    width: max-content;
}

.sortable-tree-wrapper {
    width: 100%;
    overflow: overlay;
}

:root {

}

.container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
}

.full-width {
    width: 100%;
}

.half-width {
    width: 49%;
}

.new-item {
    padding: 1px;
}

.exist-item {
    padding: 10px;
}

.modal-scrollable-content {
    overflow-y: hidden;
    height: 90vh;
}

/* Table Style */
/* MAKE LEFT COLUMN FIXEZ */
.dp tr > :first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background: #FFFFFF;
}

.dp table tbody:nth-of-type(1) tr:nth-of-type(1) td {
    border-top: none !important;
}

.dp table thead th {
    border-top: none !important;
    border-bottom: none !important;
    box-shadow: inset 0 1px 0 #ddd, inset 0 -1px 0 #ddd;
    padding: 1px 0;
}

.time-field {
    display: flex;
    justify-content: space-between;
    grid-gap: 4px;
    gap: 4px;
}

.pmol-comments-my-calendar {
    padding: 0 10px;
    /*margin-top: -32px;*/
}

.footer-btn{
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    justify-content: flex-end;
    padding: 0 10px;
}

.finish-check-box{
    display: flex;
    align-items: flex-end;
    margin-bottom: -20px
}
.selected {
    font-weight: bold;
    color: #854114 !important;
}

/*
 _____   _           _         _                        _
|_   _| | |         | |       | |                      | |
  | |   | |__   __ _| |_ ___  | |_ ___  _ __ ___   __ _| |_ ___   ___  ___
  | |   | '_ \ / _` | __/ _ \ | __/ _ \| '_ ` _ \ / _` | __/ _ \ / _ \/ __|
 _| |_  | | | | (_| | ||  __/ | || (_) | | | | | | (_| | || (_) |  __/\__ \
 \___/  |_| |_|\__,_|\__\___|  \__\___/|_| |_| |_|\__,_|\__\___/ \___||___/

Oh nice, welcome to the stylesheet of dreams.
It has it all. Classes, ID's, comments...the whole lot:)
Enjoy responsibly!
@ihatetomatoes

*/

/* ==========================================================================
   Chrome Frame prompt
   ========================================================================== */
body {
    /*background-color: #E73038;*/
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */
p {
    line-height: 1.33em;
    color: #7E7E7E;
}

#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    /* background-color: #f4681d!important; */
}

#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #3498db; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #e74c3c; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #f9c922; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

@keyframes spin {
    0% { /* Chrome, Opera 15+, Safari 3.1+ */ /* IE 9 */
        transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
    }
    100% { /* Chrome, Opera 15+, Safari 3.1+ */ /* IE 9 */
        transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
    }
}


/* ==========================================================================
   Helper classes
   ========================================================================== */

/*
 * Image replacement
 */

.ir {
    background-color: transparent;
    border: 0;
    overflow: hidden;
    /* IE 6/7 fallback */
    *text-indent: -9999px;
}

.ir:before {
    content: "";
    display: block;
    width: 0;
    height: 150%;
}

/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */

.hidden {
    display: none !important;
    visibility: hidden;
}

/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 */

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element to be focusable
 * when navigated to via the keyboard: h5bp.com/p
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/*
 * Hide visually and from screenreaders, but maintain layout
 */

.invisible {
    visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}

/*
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */

.clearfix {
    *zoom: 1;
}

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */

@media only screen and (min-width: 35em) {
    /* Style adjustments for viewports that meet the condition */
}

@media print,
(-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 120dpi) {
    /* Style adjustments for high resolution devices */
}

/* ==========================================================================
   Print styles.
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */

@media print {
    * {
        background: transparent !important;
        color: #000 !important; /* Black prints faster: h5bp.com/s */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    /*
     * Don't show links for images, or javascript/internal links
     */
    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; /* h5bp.com/t */
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 0.5cm;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}

/*
	Ok so you have made it this far, that means you are very keen to on my code.
	Anyway I don't really mind it. This is a great way to learn so you actually doing the right thing:)
	Follow me @ihatetomatoes
*/

/* MAKE LEFT COLUMN FIXEZ */
.dp tr > :first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background: 'white';
}

.dp table tbody:nth-of-type(1) tr:nth-of-type(1) td {
  border-top: none !important;
}

.dp table thead th {
  border-top: none !important;
  border-bottom: none !important;
  box-shadow: inset 0 1px 0 #ddd, inset 0 -1px 0 #ddd;
  padding: 1px 0;
}
.wrapper {
    height: calc(100vh - 14vh) !important;
    position: relative;
}

.selected {
    font-weight: bold;
    color: #854114 !important;
}

.hr {
    margin-bottom: 15px;
    border-top: 1px solid #d2d2d2;
}

.full-width {
    width: 100%;
}

.half-width {
    width: 49%;
}

.icon {
    display: inline-block;
    font-family: 'FabricMDL2Icons', F, sans-serif;
    font-style: normal;
    font-weight: 400;
    speak: none;
    margin: 4px 8px;
    cursor: pointer;
}

.display-none {
    display: none;
}

.collapsed {
    transition: none !important;
    min-width: 32px !important;
}

.expand {
    transition: none !important;
    min-width: 255px !important;
}

.event-body-container {
    text-decoration: none;
    position: relative;
}
.event-body-container .tooltip-text {
    background-color: #fff;
    z-index: 10;
    /* Fade effect */
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 2px;
    font-size: 11px;
    font-weight: 400;
    border: #e0e0e0 solid 1px;
    padding: 2px 6px;
}
.event-body-container #tooltip-id {
    display: none;
}
.event-body-container:hover #tooltip-id {
    display: block;
    position: fixed;
    overflow: hidden;
    opacity: 1;
}

.short-cut-pane-wrapper {
    height: calc(100vh - 14vh) !important;
    position: relative;
}
.wrapper {
    height: calc(100vh - 30vh) !important;
    position: relative;
}

.full-screen-wrapper {
    height: calc(100vh - 25vh) !important;
    position: relative;
}
.wrapper {
    height: 86.5vh !important;
    position: relative;
}

.full-width {
    width: 100%;
}

.main-layout-document-pane-stack {
    width: 100%;
    height: 80.8vh;
    overflow: auto;
    align-self: center;
    padding: 1rem;
}

.document-pane-body {
    height: 80vh;
    overflow: overlay;
    width: 75vh;
}

.full-width {
    width: 100%;
}

.issue .full-width{
    margin-top: 20px
}

.half-width {
    width: 49%
}

.add-new-task-form {
    height: 100%;
    width: 99%;
    overflow: auto
}

.task-form {
    width: auto;
}

.selected-tree-item {
    background: #9ccfff !important;
}
.wrapper {
    height: calc(100vh - 155px) !important;
    position: relative;
}

.selected {
    font-weight: bold;
    color: #854114 !important;
}

.full-width {
    width: 100%;
}

.main-layout-document-pane-stack {
    width: 98%;
    height: calc(100vh - 183px);
    overflow: auto;
    align-self: center;
    padding: 1rem;
}

.divider-styles {
    margin: 5px 0;
}

.time-line-wrapper {
    display: flex;
    flex-direction: column;
}

.time-line-item-wrapper {
    padding: 8px;
}

.time-line-date-style {
    display: flex;
    flex-direction: column;
}

.time-line-title-style {
    display: flex;
    flex-direction: column;
}

#task-other-people-input {
    min-height: 20px;
    min-width: 244px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(209, 209, 209) rgb(209, 209, 209) rgb(97, 97, 97);
    border-image: initial;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
    text-align: left;
    color: #242424;
    padding: 5px 10px;
}
/* Refactor this file later */
.document-pane-field-wrapper {
    width: 100%;
    margin-top: 20px;
}

.document-pane-stack {
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
}

.document-pane-stack-item {
    width: 100%;
    padding-top: 5px;
}

.list-pane-column-header {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    grid-gap: 8px;
    gap: 8px;
}
